<template>
    <div v-if="showService" @click="focusOn" class="service-quantity px-0 mx-0">
        <div class="service-row col-md-6">
            <label>
                <strong v-if="recordStore.BookingDays[dayId].BookingDayServices[i].Optional">
                    <i>{{tr('Optional')}}</i>
                </strong>
                {{recordStore.BookingDays[dayId].BookingDayServices[i].serviceName }}
                {{recordStore.BookingDays[dayId].BookingDayServices[i].serviceTypeCategory}}
                {{recordStore.BookingDays[dayId].BookingDayServices[i].supplierNameQuote}}
                <i class="text-danger" v-if="recordStore.BookingDays[dayId].BookingDayServices[i].Status=='CANCELED'">
                  {{tr('Canceled')}}
                </i>
            </label>
        </div>
        <div class="d-flex justify-content-center form-row col-md-6 px-0 mx-0">
            <div v-for="pType of getPaxTypesByBase" class="row cost-input-row col-12" v-if="quote && quote[pType]">
                <div class="form-group col service-row col-input" v-if="!user.AgencyId">
                    <input v-model="quote[pType].Include"
                        type="checkbox" class="form-check-input"
                        @change="change('Include', pType)"
                        v-if="edit" :disabled="!canEdit"/>
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].Cost"
                        :addClass="{'border-danger': quote[pType].invalid.Cost, 'border-warning': quote[pType].Modified, 'line-through': recordStore.BookingDays[dayId].BookingDayServices[i].skipQuoteCanceled}"
                        :id="baseId + '-' + recordStore.BookingDays[dayId].BookingDayServices[i].dayNr + '-' + i + '-' + pType + '-Cost'"
                        v-if="quote[pType]"
                        :currency="recordStore.Currency" :disabled="!canEdit"
                        @change="change('Cost', pType)"
                    ></number-input>
                    <i class="cost-alert cursor-pointer" v-if="costError[pType]" >
                      <font-awesome-icon icon="exclamation" @click="setOver(pType)"/>
                      <div v-if="upOver==pType" @click="setOverLeave" class="cost-value cursor-pointer">
                          {{tr('Current cost')}}: {{costValue[pType] | formatNumber({c: recordStore.Currency}) }}
                      </div>
                    </i>
                </div>
                <div class="form-group col service-row col-input" v-if="!user.AgencyId">
                    <number-input
                        :changeStyle="true"
                        :class="{'line-through': recordStore.BookingDays[dayId].BookingDayServices[i].skipQuoteCanceled}"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].Markup"
                        :addClass="quote[pType].MarkupModified? 'border-warning': ''"
                        v-if="quote[pType]"
                        @change="change('Markup', pType)"
                        :currency="recordStore.Currency" :disabled="!canEdit"
                    ></number-input>
                </div>
                <div class="form-group col service-row col-input">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].Price"
                        :id="baseId + '-' + recordStore.BookingDays[dayId].BookingDayServices[i].dayNr + '-' + i + '-' + pType + '-Price'"
                        :addClass="{'border-danger': quote[pType].isErrorPrice, 'border-warning': quote[pType].PriceModified, 'border-danger': quote[pType].invalid.Price, 'line-through': recordStore.BookingDays[dayId].BookingDayServices[i].skipQuoteCanceled}"
                        v-if="quote[pType]"
                        :disabled="!canEdit"
                        :currency="recordStore.Currency"
                        @change="change('Price', pType)"
                    ></number-input>
                </div>
                <div class="form-group col service-row col-input col-input-sm">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :addClass="{'border-danger': quote[pType].invalid.Quantity}"
                        :v.sync="quote[pType].Quantity"
                        :currency="recordStore.Currency"
                        :d="0"
                        @change="change('Quantity', pType)"
                        :disabled="!canEdit">
                    </number-input>
                </div>
                <div class="form-group col service-row col-input-xsm" v-if="tourLeader">
                    <span class="small text-nowrap" v-if="tourLeader && costByPerson(pType)">({{quote[pType].Quantity + 1}})</span>
                </div>
                <div class="form-group col service-row col-input">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].TotalCost"
                        :currency="recordStore.Currency" :disabled="true">
                    </number-input>
                </div>
                <div class="form-group col service-row col-input">
                    <number-input
                        :changeStyle="true"
                        class="my-0 mx-1"
                        :v.sync="quote[pType].TotalPrice"
                        :currency="recordStore.Currency" :disabled="true">
                    </number-input>
                </div>
                <div class="form-group col service-row col-input pl-1" v-if="paxTypes[baseId]>1">
                    <label>{{pType}}</label>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
const bookingQuoteService = importVueComp('components/booking', 'BookingQuoteService');
export default {
    name: 'booking-quote-service-quant',
    mixins: [bookingQuoteService],
    computed: {
        paxTypes () {
            let res = {};
            for (let baseId in this.recordStore.BaseQuotes.bases){
                let quote = this.recordStore.BaseQuotes[baseId];
                let c = 1;
                if (quote.CHD > 0) c += 1;
                if (quote.TL > 0) c += 1;
                res[baseId] = c;
            }
            return res;
        },
        tourLeader () {
            return this.recordStore.BaseQuotes[this.baseId].TourLeader;
        },
        baseQuote () {
            return this.recordStore.BaseQuotes[this.baseId];
        }
    },
    methods: {
        costByPerson (pType) {
            if (!this.quote[pType].CostBy && this.quote[pType].Quantity && this.quote[pType].Quantity != 1
                && this.quote[pType].Quantity <= this.baseQuote.paxQuantity) return true;
            return this.quote[pType].CostBy == 'PERSON' || this.quote[pType].CostBy == 'BASE_PERSON';
        }
    },
}
</script>
