<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" width="100.000000pt" height="76.000000pt" viewBox="0 0 100.000000 76.000000" preserveAspectRatio="xMidYMid meet">
    <g transform="translate(0.000000,76.000000) scale(0.100000,-0.100000)" fill="currentColor" stroke="none">
      <path d="M298 609 c-16 -9 -27 -69 -12 -69 3 0 22 11 42 25 35 24 44 25 172 25 128 0 137 -1 172 -25 20 -14 39 -25 42 -25 4 0 6 13 6 30 0 19 -7 33 -19 40 -26 13 -380 13 -403 -1z"/>
      <path d="M364 509 c-13 -22 23 -29 146 -27 103 3 125 6 125 18 0 12 -23 15 -132 18 -90 2 -134 -1 -139 -9z"/>
      <path d="M215 433 c-38 -9 -45 -32 -45 -154 0 -65 4 -120 8 -123 5 -3 19 8 31 24 l22 30 262 0 c221 0 266 -2 285 -16 12 -8 22 -22 22 -30 0 -34 18 -10 24 32 13 97 7 190 -13 215 l-19 24 -279 2 c-153 0 -287 -1 -298 -4z"/>
    </g>
  </svg>

</template>

<script>
export default {
    name: 'sgl-bed',
}
</script>
