<template>
    <div class="modal fade bd-example-modal-lg" id="booking-quote-variants" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="booking-quote-variants" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-auto" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{tr('Quote Variants')}}:
              </h5>
              <button type="button" class="btn btn-primary btn-sm ml-5" v-if="!ready"
                  @click="save">{{tr('Done')}}</button>
              <button type="button" class="btn btn-primary btn-sm ml-5" @click="removeClose">{{tr('Delete')}}</button>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close">
                  <span aria-hidden="true">&times;</span>
                </button>
          </div>
          <div class="modal-header form-material">
              <div class="form-row">
                  <div v-for="(variant, i) of variants" class="form-variant col-12 my-0 py-0">
                      <div class="form-row d-flex align-items-center">
                          <label class="mr-4 col-2">{{tr('Variant')}} # {{parseInt(i)+1}}:</label>
                          <input-field
                              class="col-4"
                              addClass="my-2"
                              :field="{name: 'Name', editor: 'text', label: 'Variant Name'}"
                              :defValue.sync="variant.Name"
                          ></input-field>
                          <div class="col-2">
                            <select-input
                                class="my-2"
                                :currentValue.sync="variant.HotelCategoryId"
                                :id="i"
                                @change="setHotelCategory"
                                :fieldOptions="categoriesOptions">
                            </select-input>
                          </div>
                          <i class="add-item cursor-pointer ml-2" @click="removeRow(i)" v-if="i>1">
                             <font-awesome-icon icon="trash-alt"/>
                          </i>
                      </div>
                  </div>
                  <div class="d-flex align-items-center col-12 mt-2">
                      <i class="add-item cursor-pointer ml-3" @click="addNewVariant(true)">
                          <font-awesome-icon icon="plus-circle"/>
                      </i>
                  </div>
              </div>
          </div>
          <div class="modal-body form-material" v-if="saved">
              <table class="table table-striped  table-sm table-bordered" id="table-variants">
                  <thead>
                      <tr class="table-secondary">
                          <th class="table-head-th variant-day">{{tr('Day')}}</th>
                          <th class="table-head-th variant-service">{{tr('Service')}}</th>
                          <th class="table-head-th variant-value" v-for="(g, k) of variants">
                              {{g.Name || '#' + (parseInt(k) + 1)}}
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(row, i) in serviceList" :class="{'bg-white': row.rowWhite, 'bg-white-blue': !row.rowWhite}">
                          <td v-if="row.k == 0" :rowspan="row.services" class="variant-day">
                              <div class="text-center">
                                  <span class="text-nowrap">{{date(row.date)}}</span>&nbsp
                                  <span class="text-nowrap">{{tr('Day')}} {{row.dayNr+1}}</span>
                              </div>
                          </td>
                          <td class="text-left px-1 variant-service">
                              {{row.s.Name || row.s.Service.Name}}
                          </td>
                          <td class="variant-value" v-for="(g, variantId) of variants" v-if="ready">
                              <input v-model="row.s.Quote.QuoteList[baseId].Variants[variantId]" type="checkbox"
                                @change="sumVariant"/>
                          </td>
                      </tr>
                      <tr class="table-secondary">
                          <th class="table-head-th variant-day"></th>
                          <th class="table-head-th variant-service">{{tr('Rooms')}}</th>
                          <th class="table-head-th variant-value" v-for="g of variants"></th>
                      </tr>
                      <tr v-for="(row, i) in hotelList" :class="{'bg-white': row.rowWhite, 'bg-white-blue': !row.rowWhite}">
                          <td v-if="row.k == 0" :rowspan="row.rooms" class="variant-day">
                              <div class="text-center">
                                  <span class="text-nowrap">{{date(row.date)}}</span>&nbsp
                                  <span class="text-nowrap">{{tr('Day')}} {{row.dayNr+1}}</span>
                              </div>
                          </td>
                          <td class="text-left px-1 variant-service">
                              {{row.room.Hotel.Name}} {{tr('Nights')}} {{row.room.Rooms}} {{row.room.Room.Name}}
                              {{row.room.Category.Name}}
                          </td>
                          <td class="variant-value" v-for="(g, variantId) of variants" v-if="ready && row.room.QuoteList[baseId]">
                              <input v-model="row.room.QuoteList[baseId].Variants[variantId]" type="checkbox"
                                  v-if="row.room.QuoteList[baseId].Variants"
                                  @change="sumVariant"/>
                          </td>
                      </tr>
                      <tr class="table-secondary">
                          <th class="table-head-th" colspan="2">{{tr('Flights')}}</th>
                          <th class="table-head-th" v-for="g of variants"></th>
                      </tr>
                      <tr v-for="(flight, i) in recordStore.BookingFlights">
                          <td class="text-left px-1" colspan="2">
                              {{flight.flightRoute}}
                          </td>
                          <td class="variant-value" v-for="(g, variantId) of variants" v-if="ready">
                              <input v-model="flight.QuoteList[baseId].Variants[variantId]" type="checkbox"
                                  v-if="flight.QuoteList[baseId].Variants"
                                  @change="sumVariant"/>
                          </td>
                      </tr>
                  </tbody>
                  <thead>
                      <tr>
                          <td class="variant-day">
                          </td>
                          <td class="text-center px-1 variant-service">
                              <b>{{tr('Total per Pax')}} ADT</b>
                          </td>
                          <td class="text-right variant-value" v-for="(g, variantId) of variants" v-if="ready">
                              <b v-if="totalByPaxVariant[variantId]">{{totalByPaxVariant[variantId].ADT.Price | formatNumber({c: recordStore.PriceCurrency, d: 0})}}</b>
                          </td>
                      </tr>
                      <tr v-if="hasChild">
                          <td class="variant-day">
                          </td>
                          <td class="text-center variant-service">
                              <b>{{tr('Total per Pax')}} CHD</b>
                          </td>
                          <td class="text-right variant-value" v-for="(g, variantId) of variants" v-if="ready">
                              <b v-if="totalByPaxVariant[variantId]">{{totalByPaxVariant[variantId].CHD.Price  | formatNumber({c: recordStore.PriceCurrency, d: 0})}}</b>
                          </td>
                      </tr>
                      <tr v-if="hasTL">
                          <td class="variant-day">
                          </td>
                          <td class="text-center variant-service">
                              <b>{{tr('Total per Pax')}} TL</b>
                          </td>
                          <td class="text-right variant-value" v-for="(g, variantId) of variants" v-if="ready">
                              <b v-if="totalByPaxVariant[variantId]">{{totalByPaxVariant[variantId].TL.Price  | formatNumber({c: recordStore.PriceCurrency, d: 0})}}</b>
                          </td>
                      </tr>
                  </thead>

              </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'booking-quote-variants',
    mixins: [escape],
    props: ['quoteVariants', 'baseId'],
    data () {
        return {
            variants: {},
            ready: false,
            totalByPaxVariant: {},
            sumUpVariants: null,
            saved: false,
        }
    },
    async mounted () {
        $('#booking-quote-variants').modal({backdrop: 'static', keyboard: false}, 'show');
        if (this.recordStore.QuoteVariants && this.recordStore.QuoteVariants[this.baseId]) {
            this.variants = Object.assign({}, this.recordStore.QuoteVariants[this.baseId]);
            this.updateTotals();
            this.saved = true;
            this.ready = true;
        }
        if (!this.variants || Object.keys(this.variants).length == 0) {
            this.addNewVariant();
            this.updateTotals();
        }
        this.fixTable();
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        serviceList () {
            let res = [];
            let days = {};
            for (let dayId in this.recordStore.BookingDays) {
                days[dayId] = 0;
                let k = 0;
                let day = this.recordStore.BookingDays[dayId];
                for (let sId in day.BookingDayServices) {
                    let s = day.BookingDayServices[sId];
                    if (!this.showService(s)) continue;
                    days[dayId] += 1;
                    res.push({s, dayId, sId, dayNr: day.DayNr, date: day.TransDate, k});
                    k += 1;
                }
            }
            let white = true;
            let lastDay;
            for (let row of res) {
                row.services = days[row.dayId];
                if (row.dayId != lastDay) {
                    white = !white;
                }
                row.rowWhite = white;
                lastDay = row.dayId;
            }
            return res;
        },
        hotelList () {
            let res = [];
            let days = {};
            for (let dayId in this.recordStore.BookingDays) {
                days[dayId] = 0;
                let k = 0;
                let day = this.recordStore.BookingDays[dayId];
                for (let hotel of day.BookingDayHotels) {
                    for (let rId in hotel.BookingDayRooms) {
                        let room = hotel.BookingDayRooms[rId];
                        if (!this.showRoom(room, day.DayNr)) continue;
                        days[dayId] += 1;
                        res.push({room, dayId, rId, dayNr: day.DayNr, date: day.TransDate, k, roomsCapacity: hotel.roomsCapacity});
                        k += 1;
                    }
                }
            }
            let white = true;
            let lastDay;
            for (let row of res) {
                row.rooms = days[row.dayId];
                if (row.dayId != lastDay) {
                    white = !white;
                }
                row.rowWhite = white;
                lastDay = row.dayId;
            }
            return res;
        },
        hasChild () {
            return this.recordStore.hasChild(this.baseId);
        },
        hasTL () {
            return this.recordStore.hasTL(this.baseId);
        },
        hotelCategories () {
            return this.recordStore._hotelCategories;
        },
        categoriesOptions () {
            let res = [{value: null, label: ''}];
            for (let id in this.hotelCategories) {
                res.push({value: id, label: this.hotelCategories[id]})
            }
            return res;
        }

    },
    methods: {
        async close () {
            if (!this.ready && !this.saved) {
                await this.remove();
            }
            this.eventUpdates();
            this.$emit('update:quoteVariants', false);
            $('#booking-quote-variants').modal('hide');
        },
        eventUpdates () {
            EventBus.$emit('update-totals');
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-quotes-hotels');
        },
        async fixTable () {
            return;
            await this.$nextTick();
            setTimeout(() => {
                var $table = $('#table-variants'),
                    $bodyCells = $table.find('tbody tr:first').children(),
                    colWidth;

                // Get the tbody columns width array
                colWidth = $bodyCells.map(function() {
                    return $(this).width();
                }).get();

                // Set the width of thead columns
                $table.find('thead tr').each(function (i, v) {
                    $(this).children().each((i, v) => {
                        $(v).width(colWidth[i]);
                    });
                });

            }, 500)
        },
        date (date) {
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        showService (s) {
            if (s.Service && s.Service.NotPrice) return true;
            let found = false;
            if (s.Quote.QuoteList && s.Quote.QuoteList[this.baseId]) {
                for (let p in s.Quote.QuoteList[this.baseId]) {
                    if (s.Quote.QuoteList[this.baseId][p] && s.Quote.QuoteList[this.baseId][p].Include) return true;
                }
            }
        },
        addNewVariant (update) {
            this.addVariant(this.baseId);
            this.variants = Object.assign({}, this.recordStore.QuoteVariants[this.baseId]);
            if (update) {
                this.save();
            }
        },
        addVariant (baseId) {
            this.recordStore.addVariant(baseId);
        },
        removeRow (i) {
            delete this.variants[i];
            this.recordStore.removeVariant(this.baseId, i)
            this.variants = Object.assign({}, this.variants);
            this.updateTotals();
            this.fixTable();
            this.eventUpdates();
        },
        async save () {
            this.recordStore.saveVariants(this.baseId, this.variants);
            this.updateTotals();
            this.saved = true;
            this.ready = true;
            this.fixTable();
            this.eventUpdates();
        },
        showRoom (roomRow, dayNr) {
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && roomRow.QuoteList[this.baseId] && (roomRow.QuoteList[this.baseId].Include)) return true;
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && !roomRow.QuoteList[this.baseId]) return true;
        },
        async remove () {
            this.ready = false;
            this.recordStore.removeVariants(this.baseId)
        },
        async removeClose () {
            await this.remove()
            await this.$nextTick();
            await this.close();
        },
        async sumVariant () {
            //this.recordStore.forceVariantGetters = !this.recordStore.forceVariantGetters;
            this.recordStore.sumVariant(this.baseId);
            await this.$nextTick();
            this.updateTotals();
        },
        updateTotals () {
            //let v = this.getTotalByPaxVariant()[this.baseId];
            //if (!v) this.recordStore.forceGetter('totalByPaxVariant');
            this.totalByPaxVariant = Object.assign({}, this.recordStore.totalByPaxVariant[this.baseId]);
            this.sumUpVariants = Object.assign({}, this.recordStore.sumUpVariants[this.baseId]);
        },
        setHotelCategory (value, variantId) {
            //this.recordStore.QuoteVariants[0][0].HotelCategoryId = value;
            this.recordStore.QuoteVariants = Object.assign({}, this.recordStore.QuoteVariants)
            this.recordStore.sumVariant(this.baseId);
            this.setVariantHotelCategory(this.baseId, variantId, value);
        },
        setVariantHotelCategory (baseId, variantId, value) {
            for (let day of this.recordStore.BookingDays) {
                for (let hotel of day.BookingDayHotels) {
                    for (let room of hotel.BookingDayRooms) {
                        if (!room.QuoteList) continue;
                        if (!room.QuoteList[baseId]) continue;
                        if (!room.QuoteList[baseId].Variants) continue;
                        if (room.HotelCategoryId == value) {
                             room.QuoteList[baseId].Variants[variantId] = true;
                        } else {
                             room.QuoteList[baseId].Variants[variantId] = false;
                        }
                    }
                }
            }
            this.updateTotals();
        }
    },
}
</script>

<style scoped>

thead, tbody { display: block; }

tbody {
    height: 50vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.bg-white td {
  background-color: white;
}
.bg-white-blue {
  background-color: #f6f9ff !important;
}
.bg-white-blue td {
  background-color: #f6f9ff;
}

.variant-day {
    width: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
}

.variant-service {
    width: 600px !important;
    min-width: 600px !important;
    max-width: 600px !important;
}

.variant-value {
    width: 90px !important;
    min-width: 90px !important;
    max-width: 90px !important;
}

.modal-auto {
    width: min-content;
    max-width: min-content;
}

</style>

