<template>
    <div class="col-md-12">
        <div v-for="pType of getPaxTypesByBase" >
            <div class="form-row" v-if="totals && totals[baseId] && totals[baseId][pType] && recordStore.Totals[baseId]">
                <div class="col-md-1">
                </div>
                <div class="col form-group ">
                    <input v-model="recordStore.Totals[baseId][pType].Selected" type="checkbox"
                        class="form-check-input" :disabled="!canEdit" @change="setSelected"/>
                    <label v-if="totals[baseId][pType].Rooms">{{pType}} {{tr('Room')}} {{getRoomsNames(totals[baseId][pType].Rooms)}} </label>
                    <label v-else>{{pType}} </label>
                </div>
                <div class="col">
                    <number-input
                        v-if="recordStore.totals[baseId][pType]"
                        :addClass="recordStore.Totals[baseId][pType].OverPrice? 'border-warning': ''"
                        :currency="recordStore.Currency"
                        :label="recordStore.CurrencyId"
                        :changeStyle="true"
                        :disabled="!canEdit"
                        @change="change($event, baseId, pType, 'Price')"
                        :v.sync="recordStore.totals[baseId][pType].Price"></number-input>
                </div>
                <div class="col" v-if="recordStore.CurrencyId!=recordStore.PriceCurrencyId">
                    <number-input
                        :label="recordStore.PriceCurrencyId"
                        :v="getTotalInCurrency(totals[baseId][pType].Price)"
                        :currency="recordStore.PriceCurrency"
                        :changeStyle="true"
                        disabled="true"
                    ></number-input>
                </div>
                <div class="col-md-5 form-group form-default">
                    <input v-model="recordStore.Totals[baseId][pType].Comment" class="form-control"
                        :class="{'fill': recordStore.Totals[baseId][pType].Comment}"
                        type="text" :disabled="!canEdit">
                        <span class="form-bar"></span>
                        <label class="float-label">
                                {{tr('Comment')}}
                        </label>
                    </input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteTotalAll = importVueComp('components/booking', 'BookingQuoteTotalAll');
export default {
    name: 'booking-quote-total-service',
    mixins: [bookingQuoteTotalAll],
}
</script>

