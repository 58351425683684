<template>
    <tr v-if="quote && showRoom" @click="focusOn" :class="{'bg-white': row.rowWhite, 'bg-white-blue': !row.rowWhite}">
        <td v-if="row.k == 0" :rowspan="row.rooms" class="th-day">
            <div class="text-center" :class="{}">
                <span class="text-nowrap">{{date}}</span>&nbsp
                <span class="text-nowrap">{{tr('Day')}} {{row.dayNr+1}}</span>
            </div>
        </td>
        <td class="text-left px-1" :class="{'w-50': type=='PERSON', 'mw-30': type!='PERSON'}" @contextmenu="handleClickName($event)">
            <a href="#" data-toggle="modal"
                :id="getId()"
                :class="{'text-danger': roomPaxError}"
                data-target="#select-hotel-modal"
                @click="editHotel"
            >
              {{roomQuote.hotelName}} {{roomQuote.days}} {{tr('Nights')}} {{displayName}}
            </a>
        </td>
        <td v-if="edit" class="col-value-xs">
            <input v-model="quote.Include" type="checkbox"
                :disabled="!canEditInclude"
                @change="change('Include')"
                @contextmenu="handleClick($event)"
                class=""/>
        </td>
        <td class="col-value" v-if="type!='PERSON'">
            <number-input
                class="my-0 mx-0"
                :changeStyle="true"
                :v.sync="quote.Cost"
                :addClass="{'border-danger': quote.invalid.Cost, 'border-warning': quote.Modified, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-Cost' "
                v-if="quote"
                :currency="recordStore.Currency" :disabled="!canEdit"
                @change="change('Cost')"
            ></number-input>
        </td>
        <td class="col-value-sm"  v-if="type!='PERSON'">
            <number-input :v.sync="quote.Markup"
                :changeStyle="true"
                :class="{'line-through': row.room.Status=='CANCELED'}"
                class="my-0 mx-0"
                :addClass="quote.MarkupModified? 'border-warning': ''"
                @change="change('Markup')"
                v-if="quote"
                :currency="recordStore.Currency" :disabled="!canEdit">
            </number-input>
        </td>
        <td class="col-value" v-if="type!='PERSON'">
            <number-input :v.sync="quote.Price"
                class="my-0 mx-o"
                :changeStyle="true"
                :addClass="{'border-danger': roomQuote.isErrorPrice, 'border-warning': quote.PriceModified, 'border-danger': quote.invalid.Price, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-Price' "
                @change="change('Price')"
                v-if="quote"
                :currency="recordStore.Currency" :disabled="!canEdit">
            </number-input>
        </td>
        <td class="col-value-sm">
            <number-input
                class="my-0 mx-0"
                :changeStyle="true"
                :d="0"
                :v.sync="Quantity"
                @change="setQuantity()"
                v-if="quote" :disabled="!canEdit"
            ></number-input>
        </td>
        <td class="col-value-sm disabled" v-if="type!='PERSON'">
            <number-input
                :d="0"
                class="my-0 mx-0"
                :changeStyle="true"
                :v.sync="quote.Pax"
                :disabled="hasGroups"
                @change="change('Pax')"
                v-if="quote"
            ></number-input>
        </td>
        <td class="col-value-sm" v-if="type!='SERVICE'">
            <number-input
                :changeStyle="true"
                class="my-0 mx-0"
                :d="0"
                :v.sync="quote.RoomPax"
                @change="change('RoomPax')"
                v-if="quote"
            ></number-input>
        </td>
        <td class="col-value" v-if="quote && type!='SERVICE'" :class="{'disabled': quote.UnitCostBy == 'ROOM' && quote.PeriodCostBy == 'NIGHT'} ">
            <number-input :v.sync="quote.RoomPersonCost"
                :changeStyle="true"
                class="my-0 mx-0"
                :addClass="{'border-danger': roomQuote.isErrorPrice, 'border-warning': quote.Modified, 'border-danger': quote.invalid.Cost, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-PersonCost' "
                @change="change('RoomPersonCost')"
                :disabled="!isCheckin"
                v-if="quote && quote.UnitCostBy == 'ROOM' && quote.PeriodCostBy == 'NIGHT'" :currency="recordStore.Currency"
            ></number-input>
            <number-input
                class="my-0 mx-0"
                :v.sync="quote.Cost"
                :changeStyle="true"
                :addClass="{'border-danger': quote.invalid.Cost, 'border-warning': quote.Modified, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-PersonCost' "
                v-else-if="quote"
                :currency="recordStore.Currency" :disabled="!canEdit"
                @change="change('Cost')"
            ></number-input>

        </td>
        <td class="col-value-sm"  v-if="type=='PERSON'">
            <number-input :v.sync="quote.Markup"
                :class="{'line-through': row.room.Status=='CANCELED'}"
                :changeStyle="true"
                class="my-0 mx-0"
                :addClass="quote.MarkupModified? 'border-warning': ''"
                @change="change('Markup')"
                v-if="quote"
                :currency="recordStore.Currency" :disabled="!canEdit">
            </number-input>
        </td>
        <td class="col-value disabled"  v-if="type!='SERVICE'">
            <number-input :v.sync="quote.RoomPersonPrice"
                :changeStyle="true"
                class="my-0 mx-0"
                :addClass="{'border-danger': roomQuote.isErrorPrice, 'border-warning': quote.PriceModified, 'border-danger': quote.invalid.Price, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-PeronPrice' "
                @change="change('PersonPrice')"
                :disabled="true"
                v-if="quote" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value disabled" v-if="type=='ALL'">
            <number-input :v.sync="quote.PersonCost"
                :changeStyle="true"
                class="my-0 mx-0"
                :addClass="{'border-danger': roomQuote.isErrorPrice, 'border-warning': quote.Modified, 'border-danger': quote.invalid.Cost, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-PersonCost' "
                @change="change('PersonCost')"
                disabled="true"
                v-if="quote" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value disabled"  v-if="type=='ALL'">
            <number-input :v.sync="quote.PersonPrice"
                :changeStyle="true"
                class="my-0 mx-0"
                :addClass="{'border-danger': roomQuote.isErrorPrice, 'border-warning': quote.PriceModified, 'border-danger': quote.invalid.Price, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-PeronPrice' "
                @change="change('PersonPrice')"
                disabled="true"
                v-if="quote" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value" v-if="type=='PERSON'">
            <number-input
                :changeStyle="true"
                class="my-0 mx-0"
                :v.sync="quote.Cost"
                :addClass="{'border-danger': quote.invalid.Cost, 'border-warning': quote.Modified, 'line-through': row.room.Status=='CANCELED'}"
                :id="'H-' + baseId + '-' + roomQuote.dayNr + '-' + roomIdx + '-Cost' "
                v-if="quote && quote.UnitCostBy == 'ROOM' && quote.PeriodCostBy == 'NIGHT' && !isCheckin"
                :currency="recordStore.Currency" :disabled="!canEdit"
                @change="change('Cost')"
            ></number-input>
        </td>
        <td class="col-value disabled" v-if="type!='PERSON'">
            <number-input
                class="my-0 mx-0"
                :changeStyle="true"
                :v="quote.TotalCost"
                v-if="quote" :currency="recordStore.Currency" :disabled="true"
            ></number-input>
        </td>
        <td class="col-value disabled" v-if="type!='PERSON'">
            <number-input
                class="my-0 mx-0"
                :changeStyle="true"
                :v="quote.TotalPrice"
                v-if="quote" :currency="recordStore.Currency" :disabled="true"
            ></number-input>
        </td>
        <td class="col-value-lg" v-if="type!='PERSON'">
            <div class="d-flex">
                <select-input
                    class="mx-1 my-0"
                    fieldName="UnitCostBy"
                    :fieldOptions="costByOptions.UnitCostBy"
                    addClass="max-100"
                    @change="change('UnitCostBy')"
                    :currentValue.sync="quote.UnitCostBy">
                </select-input>
                <select-input
                    fieldName="PeriodCostBy"
                    :fieldOptions="costByOptions.PeriodCostBy"
                    addClass="max-100"
                    @change="change('PeriodCostBy')"
                    class="mx-1 my-0"
                    :currentValue.sync="quote.PeriodCostBy">
                </select-input>
            </div>
        </td>
    </tr>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-quote-table-hotel',
    props: ['baseId', 'edit', 'row', 'i', 'type'],
    components: {
    },
    data () {
        return {
            currentDay: null,
            RoomPrice: null,
            Quantity: null,
            quote: null,
            costByOptions: {
                UnitCostBy: [
                    {value: 'ROOM', label: 'Room'},
                    {value: 'PERSON', label: 'Person'},
                ],
                PeriodCostBy: [
                    {value: 'NIGHT', label: 'Night'},
                    {value: 'PROGRAM', label: 'Stay'},
                ]
            },
            upOver: false,
            showMenu: false,
            roomsCapacity: {},
            hasGroups: false,
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            hotelValues: state => state.booking.hotelValues,
        }),
        dayId () {
            return this.row.dayId;
        },
        roomIdx () {
            return this.row.rId;
        },
        dayHotel () {
            return this.recordStore.BookingDays[this.dayId].BookingDayHotels[0];
        },
        roomQuote () {
            return this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId];
        },
        canEdit () {
            return this.salesActions;
        },
        canEditInclude () {
            return this.canEdit;
        },
        isCheckin () {
            if (this.row && this.row.room && this.row.room.Room && this.row.room.Room.Checkin) return true;
            return false;
        },
        costValue () {
            let res = null;
            if (!this.room) return res;
            if (!this.room.Hotel) return res;
            if (!this.room.Room) return res;
            if (!this.room.RoomCategory) return res;
            if (!this.hotelValues[this.room.HotelId]) return res;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id]) return res;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory]) return res;
            let hotelDate = this.recordStore.BookingDays[this.dayId].TransDate;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory][hotelDate]) return res;
            if (!this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory][hotelDate][this.roomQuote.days]) return res;
            return this.hotelValues[this.room.HotelId][this.room.Room.id][this.room.RoomCategory][hotelDate][this.roomQuote.days][this.baseId];
        },
        costError () {
            let costValue = this.costValue;
            if (this.quote && costValue && costValue > this.quote.Cost && this.quote.Include) {
                return true;
            }
        },
        date () {
            return moment(this.row.date).locale(api.language).format('DD MMM')
        },
        showRoom () {
            if (this.roomQuote && (this.roomQuote.Include || this.edit)) return true;
            if (!this.roomQuote) return true;
        },
        /*hasGroups () {
            if (this.roomQuote) {
                return this.roomQuote.Groups;
            }
        },*/
        roomPaxError () {
            if (!this.quote.Include) return;
            if (this.roomsCapacity.error) return true;
        },
        room () {
            return this.row.room;
        },
        displayName () {
            if (!this.hasGroups) return this.roomName;
            let roomGroups = [];
            let cnt = 0;
            for (let groupId in this.roomQuote.Groups) {
                cnt += 1;
                let group = this.roomQuote.Groups[groupId];
                if (!group) continue;
                if (group === true || (group.rooms && group.rooms > 0)) {
                    roomGroups.push(groupId)
                }
            }
            if (roomGroups.length != cnt) {
                let names = [];
                for (let groupId of roomGroups) {
                    names.push(this.recordStore.QuoteGroups[this.baseId][groupId].Name);
                }
                if (names.length>0) return `${this.roomName} (${names.join(', ')})`;
            }
            return this.roomName;
        },
        roomName () {
            return `${this.roomQuote.Rooms} ${this.row.room.Room.Name} ${this.roomQuote.roomCategoryName}`;
        },
        menuOptions () {
            let roomName = this.roomName + ' '+ this.roomQuote.roomCategoryName;
            if (roomName && roomName.length > 30) {
                roomName = roomName.substring(0, 30) + '...';
            }
            let hotelName = this.roomQuote.hotelName;
            if (hotelName && hotelName.length > 30) {
                hotelName = hotelName.substring(0, 30) + '...';
            }
            return [
                {label: `${tr('Refresh price for room')} ${roomName}`, value: 'r'},
                {label: `${tr('Refresh prices for hotel')} ${hotelName}`, value: 'h'},
                {label: `${tr('Rooming')}`, value: 'rooming'},
            ]
        },

    },
    async mounted () {
        await this.setQuote();
        this.setRoomValues();
        EventBus.$on('update-quotes', this.setQuote);
        EventBus.$on('update-quotes-hotels', this.setQuote);
    },
    methods: {
        setRoomValues () {
            //this.RoomCost = parseFloat(this.roomQuote.Cost) * this.roomQuote.factor / (parseInt(this.roomQuote.days) * this.roomQuote.qFactor);
            this.RoomPrice = parseFloat(this.roomQuote.Price) * this.roomQuote.factor / (parseInt(this.roomQuote.days) * this.roomQuote.qFactor);
            this.setRoomsQuantity();
        },
        setRoomsQuantity () {
            this.Quantity = this.rooms;
            if (this.roomQuote.Rooms) this.Quantity = this.roomQuote.Rooms;
        },
        editHotel () {
            this.$emit('editHotel');
        },
        getId () {
            if (!this.room) return;
            if (!this.room.Room) return;
            if (!this.room.RoomCategory) return;
            return 'hotel-day-' + this.roomQuote.dayNr + '-' + this.baseId + '-' + this.room.Room.id + '-' + this.room.RoomCategory;
        },
        async setQuote () {
            this.setRoomsQuantity();
            let error;
            if (!error && !this.recordStore.BookingDays[this.dayId]) error = true;
            if (!error && !this.recordStore.BookingDays[this.dayId].BookingDayHotels) error = true;
            if (!error && !this.recordStore.BookingDays[this.dayId].BookingDayHotels[0]) error = true;
            if (!error && !this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms) error = true;
            if (error) {
                api.post('/api/send_logs/', JSON.stringify({
                    'error': 'setQuote',
                    dayId: this.dayId,
                    roomIdx: this.roomIdx,
                    bookingId: this.recordStore.id,
                    baseId: this.baseId
                }));
                return;
            }
            if (!this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx]) return;
            let row = this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx];
            if (!row.QuoteList[this.baseId]) {
                row.QuoteList[this.baseId] = await row.QuoteList.newHotelQuote();
                row.QuoteList.getFields();
            }
            if (this.roomQuote) {
                this.hasGroups = this.roomQuote.Groups;
            }
            //console.log('setQuote', this.dayId, this.roomIdx)
            this.setCapacity();
            this.quote = Object.assign({}, this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId]);
        },
        setCapacity () {
            if (this.hasGroups) {
                this.roomsCapacity = Object.assign({}, this.room.roomsCapacity[this.baseId]);
                return;
            }
            this.roomsCapacity = Object.assign({}, this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].roomsCapacity[this.baseId]);
        },
        async change (fieldName) {
            let value = this.quote[fieldName];
            await this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId].setValue({fieldName, value});
            this.setQuote();
            EventBus.$emit('update-totals');
        },
        async setQuantity () {
            await this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.roomIdx].QuoteList[this.baseId].setValue({fieldName: 'Rooms', value: this.Quantity});
            this.dayHotel.forceCapacityGetter = !this.dayHotel.forceCapacityGetter;
            this.setQuote();
            EventBus.$emit('update-totals');
            this.updateDayRooms()
        },
        updateDayRooms () {
            for (let rId in this.dayHotel.BookingDayRooms) {
                if (this.roomIdx == rId) continue;
                let ref = `room-${this.baseId}-${this.dayId}-${rId}`;
                if (this.$parent.$refs && this.$parent.$refs[ref] && this.$parent.$refs[ref][0]) {
                    this.$parent.$refs[ref][0].setCapacity();
                }
            }
        },
        setOver () {
            this.upOver = true;
        },
        setOverLeave () {
            this.upOver = null;
        },
        focusOn () {
            this.$emit('focusOn', this.dayId, this.roomIdx)
        },
        handleClickName (event) {
            event.preventDefault();
            this.$root.$children[0].showContextMenu(event, {
                show: true,
                //ref: `quoteMenu${this._uid}`,
                ref: 'quoteMenu',
                options: this.menuOptions,
                menuClicked: this.menuClicked
            });
        },
        handleClick (event) {
            this.$emit('handleClick', event, this.dayId, 'h');
        },
        async menuClicked (option, params) {
            if (option.value == 'r') {
                //api.setProcessing(true);
                let day = this.recordStore.BookingDays[this.dayId];
                let hotelDate = day.TransDate;
                if (this.recordStore.SplitQuoteDate && this.recordStore.QuoteDate) {
                    hotelDate = day.QuoteDate;
                }
                let program;
                if (this.room && this.room.Category && this.room.Category.Program) program = this.room.RoomCategory;
                let hasProgramRooms = this.recordStore.hasProgramRooms(this.room.Hotel)
                await this.recordStore.setHotelCost({
                    idx: this.dayId,
                    hotelId: this.room.HotelId,
                    hotelDate,
                    days: day.BookingDayHotels[0].Days,
                    roomNr: this.roomIdx,
                    baseId: this.baseId,
                    program,
                    hasProgramRooms,
                    currencyId: this.room.CurrencyId,
                    skipCurrency: true,
                    updateModified: 2,
                })
                //api.setProcessing(false);
                EventBus.$emit('update-totals');
            } else if (option.value == 'h') {
                //api.setProcessing(true);
                await this.recordStore.setCostByDate({baseId: this.baseId, sync: true, supplierId: -1, hotelId: this.room.HotelId});
                //api.setProcessing(false);
                EventBus.$emit('update-totals');
            } else if (option.value == 'rooming') {
                this.$parent.showRooming = parseInt(this.dayId) + 1;
            }
            this.showMenu = false;
        }

    },
    beforeDestroy() {
        EventBus.$off('update-quotes', this.setQuote);
        EventBus.$off('update-quotes-hotels', this.setQuote);

    },
}
</script>

<style scoped>
 .th-day, .th-day div, .th-day div span {
    text-decoration: none !important;
 }
 .bg-white td {
    background-color: white;
 }
 .bg-white-blue {
    background-color: #f6f9ff !important;
 }
 .bg-white-blue td {
    background-color: #f6f9ff;
 }
 .col-value-lg {
    width: 62px !important;
    min-width: 62px !important;
    max-width: 62px !important;

 }


</style>

