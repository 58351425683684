<template>
  <div class="modal fade" id="inputQuote" tabindex="-1" role="dialog" aria-labelledby="inputQuoteLabel"
      data-backdrop="static" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{tr('Passengers')}}</h5>
        </div>
        <div class="modal-body form-material">
          <div class="form-row" v-for="g,i of groups">
            <div class="col-3 form-group form-default">
             <number-input
                label="Adults"
                :v.sync="g.adt"
                d="0"
                class="my-0"
            ></number-input>
            </div>
            <div class="col-3 form-group form-default">
             <number-input
                label="Children"
                :v.sync="g.chd"
                d="0"
                class="my-0"
              ></number-input>
            </div>
            <div class="col-3 form-group form-default">
             <number-input
                label="Tour Leader"
                :v.sync="g.tl"
                d="0"
                class="my-0"
              ></number-input>
            </div>
            <div class="col-1 d-flex align-items-center">
                <i class="add-item cursor-pointer" @click="removeGroup(i)" v-if="i>0">
                    <font-awesome-icon icon="trash-alt"/>
                </i>
            </div>
          </div>
          <!--div class="form-row">
            <i class="add-item cursor-pointer" @click="addGroup">
               <font-awesome-icon icon="plus-circle"/>
            </i>
          </div-->
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-primary" @click="save">{{tr('Done')}}</button>
          <button type="button" class="btn btn-secondary" @click="close">{{tr('Cancel')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
export default {
    name: 'input-quote',
    props: ['defADT', 'defCHD', 'defTL'],
    mixins: [escape],
    data () {
        return {
            groups: [{adt: 1, chd: 0, tl: 0}]
        }
    },
    mounted () {
        /*if (this.defADT) this.adt = this.defADT;
        if (this.defCHD) this.chd = this.defCHD;
        if (this.defTL) this.tl = this.defTL;*/
        $('#inputQuote').modal({backdrop: 'static', keyboard: false}, 'show');
    },
    methods: {
        save () {
            $('#inputQuote').modal('hide');
            this.$emit('save', this.groups);
        },
        close (){
            this.$emit('close');
            $('#inputQuote').modal('hide');
        },
        addGroup () {
          this.groups.push({adt: 0, chd: 0, tl: 0})
        },
        removeGroup (i) {
          this.groups.splice(i, 1)
        },
    },
}
</script>
