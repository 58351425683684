<template>
    <div>
        <div class="card no-border-top">
            <div class="ml-2">
                <booking-quote-config
                ></booking-quote-config>
            </div>
        </div>
        <div class="card">
            <div class="ml-5 mt-5 mb-3">
                <booking-quote-list
                ></booking-quote-list>
            </div>
        </div>
        <div class="card">
            <div class="ml-5 mt-5 mb-3">
                <booking-expenses-pax
                ></booking-expenses-pax>
            </div>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="form-row">
                    <div class="form-row col-md-12 ml-4 mt-1">
                        <booking-quote-summary-variants v-if="hasVariants"
                        ></booking-quote-summary-variants>
                        <booking-quote-summary-person v-else-if="recordStore.ShowPriceBy=='PERSON' && recordStore.QuoteBy!='QUANTITY'"
                        ></booking-quote-summary-person>
                        <booking-quote-summary v-else
                        ></booking-quote-summary>
                    </div>
                </div>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
const bookingQuoteList = importVueComp('components/booking', 'BookingQuoteList');
const bookingQuoteConfigModal = importVueComp('components/booking', 'BookingQuoteConfigModal');
const bookingQuoteConfig = importVueComp('components/booking', 'BookingQuoteConfig');
const bookingExpensesPax = importVueComp('components/booking', 'BookingExpensesPax');
const bookingQuoteSummary = importVueComp('components/booking', 'BookingQuoteSummary');
const bookingQuoteSummaryPerson = importVueComp('components/booking', 'BookingQuoteSummaryPerson');
const bookingQuoteSummaryVariants = importVueComp('components/booking', 'BookingQuoteSummaryVariants');

import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quotes',
    data () {
        return {
            rates: {},
            record: {},
            configModal: null,
            hasVariants: false,
        }
    },
    components: {
        'booking-quote-list': bookingQuoteList,
        'booking-quote-config-modal': bookingQuoteConfigModal,
        'booking-quote-config': bookingQuoteConfig,
        'booking-expenses-pax': bookingExpensesPax,
        'booking-quote-summary-person': bookingQuoteSummaryPerson,
        'booking-quote-summary-variants': bookingQuoteSummaryVariants,
        'booking-quote-summary': bookingQuoteSummary,
    },
    mounted () {
        //this.setRecord();
        this.setRates();
        this.setHasVariants();
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
        }),
        _requestCount () {
            return this.recordStore._requestCount;
        },
    },
    methods: {
        setHasVariants () {
            this.hasVariants = this.recordStore.hasVariants;
        },
        setRecord () {
            let r = tools.plainObject(this.recordStore, true);
            this.record = r;
        },
        setRates () {
            let r = tools.plainObject(this.recordStore.currencyRates, true);
            this.rates = r;
        },
    },
    watch: {
        _requestCount () {
            if (this._requestCount == 0) {
                this.recordStore.updateGetters();
                EventBus.$emit('update-quotes');
                EventBus.$emit('update-totals');
                api.setProcessing(false);
            }
            if (this._requestCount > 1) {
                api.setProcessing(true);
            }
        }
    },
}
</script>

