<template>
    <div class="col-md-12" v-if="ready">
        <div v-for="(group, groupId) of totalByPaxGroup">
            <div v-for="(value, pType) of totalByPaxGroup[groupId]" v-if="quoteGroup[groupId][pType]">
                <div class="form-row">
                    <div class="col-md-1">
                    </div>
                    <div class="col form-group ">
                        <input v-model="recordStore.QuoteGroups[baseId][groupId].Selected[pType]" type="checkbox"
                            class="form-check-input" :disabled="!canEdit" @change="setSelected"/>
                        <!--label v-if="totals[baseId][pType].Rooms">{{pType}} {{tr('Room')}} {{getRoomsNames(totals[baseId][pType].Rooms)}} </label>
                        <label v-else>{{pType}} </label-->
                        <label>{{recordStore.QuoteGroups[baseId][groupId].Name}}:  {{quoteGroup[groupId][pType]}}  {{pType}}</label>
                    </div>
                    <div class="col">
                        <number-input
                            :addClass="overPrice(groupId, pType)? 'border-warning': ''"
                            :currency="recordStore.Currency"
                            :label="recordStore.CurrencyId"
                            :disabled="!canEdit"
                            @change="setOverPrice($event, groupId, pType)"
                            :v.sync="value.Price"></number-input>
                    </div>
                    <div class="col" v-if="recordStore.CurrencyId!=recordStore.PriceCurrencyId">
                        <number-input
                            :label="recordStore.PriceCurrencyId"
                            :v="getTotalInCurrency(value.Price)"
                            :currency="recordStore.PriceCurrency"
                            disabled="true"
                        ></number-input>
                    </div>
                    <div class="col-md-5 form-group form-default">
                        <input v-model="recordStore.QuoteGroups[baseId][groupId].Comments[pType]" class="form-control"
                            :class="{'fill': recordStore.QuoteGroups[baseId][groupId].Comments[pType]}"
                            @change="setComment($event.target.value, groupId, pType)"
                            type="text" :disabled="!canEdit">
                            <span class="form-bar"></span>
                            <label class="float-label ml-2">
                                {{tr('Comment')}}
                            </label>
                        </input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteTotalAll = importVueComp('components/booking', 'BookingQuoteTotalAll');
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-quote-total-group',
    mixins: [bookingQuoteTotalAll],
    data () {
        return {
            ready: false,
            totalByPaxGroup: {},
            sumUpGroups: null,
            quoteGroup: {},
        }
    },
    mounted () {
        this.setTotals();
        this.quoteGroup = this.recordStore.QuoteGroups[this.baseId];
        EventBus.$on('update-totals', this.setTotals);
        this.ready = true;
    },
    methods: {
        setTotals () {
            this.totalByPaxGroup = Object.assign({}, this.recordStore.totalByPaxGroup[this.baseId]);
            //this.sumUpGroups = this.recordStore.sumUpGroups[this.baseId];
        },
        overPrice (groupId, pType) {
            if (this.recordStore.QuoteGroups[this.baseId][groupId].OverPrice) {
                if (this.recordStore.QuoteGroups[this.baseId][groupId].OverPrice[pType]) {
                    return true;
                }
            }
        },
        async setOverPrice (value, groupId, pType) {
            if (!this.recordStore.QuoteGroups[this.baseId][groupId].OverPrice) {
                this.recordStore.QuoteGroups[this.baseId][groupId].OverPrice = {};
            }
            this.recordStore.QuoteGroups[this.baseId][groupId].OverPrice[pType] = value;
            this.recordStore.forceGroupGetters = !this.recordStore.forceGroupGetters;
            //this.setTotals();
            EventBus.$emit('update-totals');
        },
        async setComment (value, groupId, pType) {
            if (!this.recordStore.QuoteGroups[this.baseId][groupId].Comments) {
                this.recordStore.QuoteGroups[this.baseId][groupId].Comments = {};
            }
            this.recordStore.QuoteGroups[this.baseId][groupId].Comments[pType] = value;
        }
    },
    beforeDestroy() {
        EventBus.$off('update-totals', this.setTotals);
    },
}
</script>

