<template>
    <tr v-if="quote && show"  @click="focusOn"
        :class="{'bg-white': row.rowWhite, 'bg-white-blue': !row.rowWhite}">
        <td v-if="row.k == 0" :rowspan="row.dayRowSpan" class="th-day">
            <div class="text-center">
                <span class="text-nowrap">{{date(row.date)}}</span>&nbsp
                <span class="text-nowrap">{{tr('Day')}} {{row.dayNr+1}}</span>
            </div>
        </td>
        <td v-if="row.sk == 0" :rowspan="rowSpan" class="text-left px-1" @click="serviceClick"
            :class="serviceClass" @contextmenu="handleClickName($event)">
            {{serviceName}}
        </td>
        <td v-if="edit"  class="col-value-xs">
            <input v-model="quote.Include" type="checkbox"
                :disabled="!canEditInclude"
                @change="change('Include')"
                @contextmenu="handleClick($event)"
                class=""/>
        </td>
        <td class="col-value" v-if="type!='PERSON'">
            <number-input
                class="my-0 mx-0"
                :v.sync="quote.Cost"
                :changeStyle="true"
                :addClass="{'border-danger': quote.invalid.Cost, 'border-warning': quote.Modified, 'line-through': service.Status=='CANCELED'}"
                :id="baseId + '-' + service.dayNr + '-' + i + '-' + pType + '-Cost'"
                :currency="recordStore.Currency" :disabled="!canEdit"
                @change="change('Cost')"
            ></number-input>
            <i class="cost-alert cursor-pointer" v-if="costError" >
              <font-awesome-icon icon="exclamation" @click="setOver"/>
              <div v-if="upOver==pType" @click="setOverLeave" class="cost-value cursor-pointer">
                  {{tr('Current cost')}}: {{costValue | formatNumber({c: recordStore.Currency}) }}
              </div>
            </i>

        </td>
        <td class="col-value-sm"  v-if="type!='PERSON'">
            <number-input
                :class="{'line-through': service.Status=='CANCELED'}"
                :changeStyle="true"
                class="my-0 mx-0"
                :v.sync="quote.Markup"
                :addClass="quote.MarkupModified? 'border-warning': ''"
                @change="change('Markup')"
                :currency="recordStore.Currency" :disabled="!canEdit"
            ></number-input>
        </td>
        <td class="col-value" v-if="type!='PERSON'">
            <number-input
                class="my-0 mx-0"
                :changeStyle="true"
                :v.sync="quote.Price"
                :id="baseId + '-' + service.dayNr + '-' + i + '-' + pType + '-Price'"
                :addClass="{'border-danger': quote.isErrorPrice, 'border-warning': quote.PriceModified, 'border-danger': quote.invalid.Price, 'line-through': service.Status=='CANCELED'}"
                :disabled="!canEdit"
                :currency="recordStore.Currency"
                @change="change('Price')"
            ></number-input>
        </td>
        <td class="col-value-sm" v-if="type!='PERSON'">
            <number-input
                v-if="costPer=='SERVICE'"
                :changeStyle="true"
                class="my-0 mx-0"
                :addClass="{'border-danger': quote.invalid.Quantity, 'line-through': service.Status=='CANCELED'}"
                :v.sync="quote.Quantity"
                @change="change('Quantity')"
                :disabled="!canEdit">
            </number-input>
        </td>
        <td v-if="type!='PERSON'" :class="{'col-value': showPax, 'col-value-sm': !showPax, 'disabled': hasGroups}">
            <div class="d-inline-flex align-items-center">
                <span v-if="showPax" :class="{'cursor-pointer': pType == 'CHD'}" @click="setADT" class="w-50">
                    {{pType}}:
                    <span v-if="ADTCostCHD">*</span>
                </span>
                <number-input
                    :v.sync="quote.Base"
                    class="my-0 mx-0"
                    :changeStyle="true"
                    :class="{'w-50': showPax, 'w-100': !showPax,}"
                    :disabled="hasGroups"
                    @change="change('Base')"
                ></number-input>
            </div>
        </td>
        <td class="th-cost-per"  v-if="type!='PERSON'" :class="{'disabled': serviceId && costPer}">
            <select-input fieldName="CostPer" class="my-0"
                addClass="text-small"
                :currentValue.sync="costPer"
                :addClass="{'border-danger': quote.invalid.CostBy}"
                :disabled="!canEdit || (serviceId && costPer)"
                :fieldOptions="serviceCostPerOptions"
                @change="setCostPerValue"
            ></select-input>
        </td>
        <td class="col-value"  v-if="type!='SERVICE'">
            <i class="cost-alert-table cursor-pointer" v-if="costError" >
              <font-awesome-icon icon="exclamation" @click="setOver"/>
              <div v-if="upOver" @click="setOverLeave" class="cost-value cursor-pointer">
                  {{tr('Current cost')}}: {{costValue | formatNumber({c: recordStore.Currency}) }}
              </div>
            </i>
            <number-input
                class="my-0 mx-0"
                :class="{'with-alert-table': costError}"
                :v.sync="quote.PersonCost"
                :id="baseId + '-' + service.dayNr + '-' + i + '-' + pType + '-PersonCost'"
                :addClass="{'border-danger': quote.isErrorPrice, 'border-warning': quote.Modified, 'border-danger': quote.invalid.PersonCost, 'line-through': service.Status=='CANCELED'}"
                v-if="quote"
                :disabled="!canEdit"
                :currency="recordStore.Currency"
                :changeStyle="true"
                @change="change('PersonCost')"
            ></number-input>
        </td>
        <td class="col-value-sm" v-if="type=='PERSON'">
            <number-input
                :class="{'line-through': service.Status=='CANCELED'}"
                class="my-0 mx-0"
                :v.sync="quote.Markup"
                :addClass="quote.MarkupModified? 'border-warning': ''"
                @change="change('Markup')"
                :changeStyle="true"
                :currency="recordStore.Currency" :disabled="!canEdit"
            ></number-input>
        </td>
        <td class="col-value" v-if="type!='SERVICE'">
            <number-input
                class="my-0 mx-0"
                :v.sync="quote.PersonPrice"
                :id="baseId + '-' + service.dayNr + '-' + i + '-' + pType + '-PersonPrice'"
                :addClass="{'border-warning': quote.PriceModified, 'border-danger': quote.invalid.PersonPrice, 'line-through': service.Status=='CANCELED'}"
                v-if="quote"
                :disabled="!canEdit"
                :currency="recordStore.Currency"
                :changeStyle="true"
                @change="change('PersonPrice')"
            ></number-input>
        </td>
        <td v-if="type=='PERSON'" :class="{'col-value': showPax, 'col-value-sm': !showPax, 'disabled': hasGroups}">
            <div class="d-inline-flex align-items-center">
                <span v-if="showPax" :class="{'cursor-pointer': pType == 'CHD'}" @click="setADT" class="w-50">
                    {{pType}}:
                    <span v-if="ADTCostCHD">*</span>
                </span>
                <number-input
                    :v.sync="quote.Base"
                    class="my-0 mx-0"
                    :changeStyle="true"
                    :class="{'w-50': showPax, 'w-100': !showPax,}"
                    :disabled="hasGroups"
                    @change="change('Base')"
                ></number-input>
            </div>
        </td>
        <td class="col-value disabled" v-if="type!='PERSON'">
            <number-input
                :changeStyle="true"
                class="my-0 mx-0"
                :v.sync="quote.TotalCost"
                :currency="recordStore.Currency" :disabled="true">
            </number-input>
        </td>
        <td class="col-value disabled" v-if="type!='PERSON'">
            <number-input
                :changeStyle="true"
                class="my-0 mx-0"
                :v.sync="quote.TotalPrice"
                :currency="recordStore.Currency" :disabled="true">
            </number-input>
        </td>
        <context-menu
            v-if="showMenu"
            :options="menuOptions"
            :ref="'quoteMenu' + _uid"
            @clicked="menuClicked"
        ></context-menu>
    </tr>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote-table-service',
    props: ['row', 'paxTypesQuantity', 'edit', 'baseId', 'type', 'i'],
    components: {
    },
    data () {
        return {
            serviceCostPerOptions: [
                {value: 'PERSON', label: 'Person'},
                {value: 'SERVICE', label: 'Service'},
            ],
            upOver: null,
            quote: {invalid: false},
            quoteListId: {},
            costPer: null,
            showMenu: null,
        }
    },
    mounted () {
        this.setQuote();
        this.setCostPer();
        EventBus.$on('update-quotes', this.setQuote);

    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            costValues: state => state.booking.costValues,
        }),
        show () {
            if (this.costPer != 'SERVICE') return true;
            if (this.pType == 'ADT') return true;
            return true;
        },
        serviceId () {
            return this.service.ServiceId;
        },
        canEdit () {
            return this.salesActions;
        },
        canEditInclude () {
            return this.canEdit;
        },
        rowSpan () {
            if (this.costPer == 'SERVICE') return 1;
            return this.paxTypesQuantity;
        },
        showPax () {
            if (this.costPer != 'SERVICE' && this.paxTypesQuantity > 1) return true;
        },
        service () {
            return this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId];
        },
        pType () {
            return this.row.pType;
        },
        serviceName () {
            let name = '';
            if (this.service.Service.Name) name = this.service.Service.Name;
            if (this.service.Name) name = this.service.Name;
            return `${name} ${this.service.serviceTypeCategory} ${this.service.supplierNameQuote}`;
        },
        serviceClass () {
            return '';
        },
        hasGroups () {
            if (this.quoteListId) {
                return this.quoteListId.Groups;
            }
        },
        ADTCostCHD () {
            if (this.pType != 'CHD') return;
            return this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].ADTCostCHD;
        },
        costValue () {
            let res;
            if (!this.service.ServiceId) return res;
            let id = this.service.ServiceId;
            let categoryId = this.service.Service.ServiceCategoryId;
            if (!this.costValues[[id, categoryId]]) return res;
            if (!this.costValues[[id, categoryId]][this.service.ServiceDate]) return res;
            if (!this.costValues[[id, categoryId]][this.service.ServiceDate][this.baseId]) return res;
            if (this.costValues[[id, categoryId]][this.service.ServiceDate][this.baseId][this.pType]) {
                let costValue = this.costValues[[id, categoryId]][this.service.ServiceDate][this.baseId][this.pType];
                if (costValue > this.quote.Cost &&  this.quote.Include) {
                    res = costValue;
                }
            }
            return res;
        },
        costError () {
            let res = false;
            let costValue = this.costValue;
            let decimals = this.recordStore.Currency.Decimals;
            let decimalNull = tools.isNull(decimals);
            if (decimalNull) decimals = 2;
            if (costValue && this.quote.Include && this.quote.Cost) {
                costValue = parseFloat(costValue.toFixed(decimals));
                let quoteValue = parseFloat(this.quote.Cost.toFixed(decimals));
                if (costValue > quoteValue) {
                    res = true;
                }
            }
            return res;
        },
        menuOptions () {
            let serviceName = this.serviceName;
            if (serviceName && serviceName.length > 30) {
                serviceName = serviceName.substring(0, 30) + '...';
            }
            return [
                {label: `${tr('Refresh price for service')} ${serviceName}`, value: 's'},
                {label: `${tr('Refresh prices for supplier')} ${this.service.supplierNameQuote}`, value: 'p'},
            ]
        },

    },
    methods: {
        async setQuote () {
            let quoteList = this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].Quote.QuoteList
            if (!quoteList[this.baseId]) {
                let service = this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId];
                await this.recordStore.getServicePersonCostRow({baseQuoteId: this.baseId, service});
            }
            this.quote = Object.assign({}, this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].Quote.QuoteList[this.baseId][this.pType])
            this.quoteListId = Object.assign({}, this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].Quote.QuoteList[this.baseId])
        },
        date (date) {
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        setCostPer () {
            this.costPer = this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].Quote.QuoteList[this.baseId][this.pType].costPer;
        },
        async setCostPerValue () {
            let value = this.costPer;
            let fieldName = 'CostPer';
            await this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].setValue({fieldName, value});
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
            EventBus.$emit('update-service-table');
        },
        async setADT () {
            if (this.pType != 'CHD') return;
            let value = !this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].ADTCostCHD;
            let fieldName = 'ADTCostCHD';
            await this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].setValue({fieldName, value});
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
            EventBus.$emit('update-service-table');
        },
        async change (fieldName) {
            let t1 = moment(new Date()).valueOf();
            let value = this.quote[fieldName];
            await this.recordStore.BookingDays[this.row.dayId].BookingDayServices[this.row.sId].Quote.QuoteList[this.baseId][this.pType].setValue({fieldName, value});
            let t2 = moment(new Date()).valueOf();
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        setOver () {
            this.upOver = true;
        },
        setOverLeave () {
            this.upOver = null;
        },
        focusOn () {
            this.$emit('focusOn', this.row.dayId, this.i)
        },
        serviceClick () {
        },
        handleClickName (event) {
            event.preventDefault();
            this.$root.$children[0].showContextMenu(event, {
                show: true,
                //ref: `quoteMenu${this._uid}`,
                ref: 'quoteMenu',
                options: this.menuOptions,
                menuClicked: this.menuClicked
            });
            /*this.showMenu = true;
            setTimeout(() => {
                let id = `quoteMenu${this._uid}`
                if (this.$refs[id]) {
                    this.$refs[id].showMenu(event, {});
                }
            }, 100);*/
        },
        handleClick (event) {
            this.$emit('handleClick', event, this.row.dayId, 's');
        },
        async menuClicked (option, params) {
            if (option.value == 's') {
                //api.setProcessing(true);
                let quoteDate = this.service.ServiceDate;;
                if (this.recordStore.SplitQuoteDate && this.recordStore.QuoteDate) {
                    quoteDate = this.service.$parent.QuoteDate;;
                }
                await this.recordStore.getServiceCost({baseQuoteId: this.baseId, service: this.service, quoteDate: quoteDate, updateModified: 2});
                //api.setProcessing(false);
                EventBus.$emit('update-totals');
            } else if (option.value == 'p') {
                //api.setProcessing(true);
                await this.recordStore.setCostByDate({baseId: this.baseId, sync: true, supplierId: this.service.SupplierId, hotelId: -1});
                //api.setProcessing(false);
                EventBus.$emit('update-totals');
            } else {
                //this.$emit('menuClicked', option, params);
            }
            this.showMenu = false;
        }
    },
    beforeDestroy() {
        EventBus.$off('update-quotes', this.setQuote);
        //EventBus.$off('update-service-table', this.setCostPer);
    },
}
</script>

<style scoped>
 td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.25rem!important;
    padding-right: 0.25rem!important;
 }
 .bg-white td {
    background-color: white;
 }
 .bg-white-blue {
    background-color: #f6f9ff !important;
 }
 .bg-white-blue td {
    background-color: #f6f9ff;
 }
 .th-day, .th-day div, .th-day div span {
    text-decoration: none !important;
 }

</style>
