<template>
      <div v-if="ready">
         <div class="card mb-3 no-border-top">
            <div class="card-header pt-2">
                <div class="form-row form-material">
                    <booking-cover-main></booking-cover-main>
                </div>
            </div>
         </div>

         <div class="card mb-3 desktop" v-if="recordStore.notTemplate">
            <div class="card-header">
                <div class="form-row form-material">
                    <div class="form-group col-md-4 my-0 px-3">
                        <div class="form-row rooms-config">
                            <label v-if="!firstQuote">
                                <a href="#" data-target="#inputQuote" data-toggle="modal" @click="showInput">
                                    {{tr('Add Passengers')}}
                                </a>
                            </label>
                            <label v-else>
                                <a href="#" data-target="#inputQuote" data-toggle="modal" @click="showInput">
                                    {{tr('Passengers')}}:
                                    {{quotePax}}
                                </a>
                            </label>
                        </div>
                        <div class="form-row rooms-config">
                            <div class="form-check">
                                <input v-model="recordStore.ShowDates" name="ShowDates"
                                class="form-check-input" type="checkbox" id="ShowDates"
                                :disabled="!canEdit"
                                >
                                <label class="smaller form-check-label">{{tr('Show Itinerary Dates')}}</label>
                            </div>
                        </div>
                        <div class="form-row rooms-config">
                            <div class="form-check">
                                <input v-model="recordStore.ShowServiceLanguage"
                                class="form-check-input" type="checkbox" id="ShowServiceLanguage"
                                :disabled="!canEdit">
                                <label class="smaller form-check-label">{{tr('Show Service Language')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-8 my-0">
                        <rooms-profile
                        ></rooms-profile>
                    </div>
                </div>
            </div>
         </div>


         <div class="card mb-3" v-if="recordStore.notTemplate">
            <div class="card-header pt-0">
                <booking-cover-pax></booking-cover-pax>
             </div>
         </div>

         <div class="card mb-3 desktop">
            <div class="card-header pt-0">
                <div class="form-row">
                    <div class="col-md-4 col-sm-12 mb-1">
                        <div class="card-header section-title">
                            <label>{{tr('Cover Picture')}}</label>
                        </div>
                        <photo-div
                            class="form-material"
                            :showDelete="true"
                            :disabled="!canEdit"
                            :image.sync="recordStore.Image"
                            :imageId.sync="recordStore.ImageId"
                        ></photo-div>
                    </div>
                    <div class="col-md-8 col-sm-12 mb-1 px-5">

                        <div class="form-row">
                            <div class="form-group col-md-12 mb-0">
                                <div class="card-header section-title col-12">
                                    <div class="row">
                                        <div class="col-11">
                                            <label>{{tr('Trip Summary')}}</label>
                                        </div>
                                        <div class="col-1" v-if="canEdit">
                                            <button type="button" class="btn btn-primary action-button mt-0" @click="showTextSummary">
                                                <font-awesome-icon icon="plus"/>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                             </div>
                        </div>
                        <text-area-style
                            :disabled="!canEdit"
                            :text.sync="recordStore.Summary"
                            id="summary"
                            ref="summaryText"
                        ></text-area-style>
                    </div>
                </div>
             </div>
         </div>
         <text-summary-selector @close="closeTextSummary" @add="addTextSummary" v-if="textSummary"></text-summary-selector>
         <input-quote v-if="showInputQuote" @save="addBase" @close="showInputQuote = false"></input-quote>
      </div>
</template>

<script>
import { mapState } from 'vuex';
const roomsProfile = importVueComp('components/booking', 'RoomsProfile');
const textSummarySelector = importVueComp('components/booking', 'TextSummarySelector');
const inputQuote = importVueComp('components/booking', 'InputQuote');
const bookingCoverMain = importVueComp('components/booking', 'BookingCoverMain');
const bookingCoverPax = importVueComp('components/booking', 'BookingCoverPax');

export default {
    name: 'booking-cover',
    components: {
      'rooms-profile': roomsProfile,
      'text-summary-selector': textSummarySelector,
      'input-quote': inputQuote,
      'booking-cover-main': bookingCoverMain,
      'booking-cover-pax': bookingCoverPax,
    },
    data () {
        return {
            textSummary: false,
            showInputQuote: false,
            firstQuote: null,
            quotePax: null,
            ready: false,
        }
    },
    mounted () {
        this.firstQuote = this.getFirstQuote();
        this.quotePax = this.getQuotePax();
        this.ready = true;
        if (process.env.NODE_ENV != "production") {
            window._booking = this.recordStore
        }
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
            recordStore: state => state.main.record,
        }),
        canEdit () {
            if (!this.recordStore.isTemplate) return this.salesActions || this.managerActions;
            return true;
        },
        headerFields () {
            return this.recordStore.headerFields;
        },
        headerObject () {
            let res = {};
            for (let field of this.headerFields) {
                res[field] = this.record[field];
            }
            return res;
        },
    },
    watch: {
    },
    methods: {
        getFirstQuote () {
            for (let baseId in this.recordStore.BaseQuotes.bases) {
                let quote = this.recordStore.BaseQuotes[baseId];
                if (quote.ADT) {
                    return baseId;
                };
            }
        },
        getQuotePax () {
            let res = [];
            //if (this.recordStore.bookingConfirmed) {
            //    return this.recordStore.getPaxForQuote();
            //} else {
                for (let baseId in this.recordStore.BaseQuotes.bases) {
                    for (let pType of this.recordStore.BaseQuotes[baseId].paxTypes) {
                        if (this.recordStore.Totals[baseId][pType].Selected) {
                            if (this.recordStore.Totals[baseId][pType].Pax)  {
                                res.push(this.recordStore.Totals[baseId][pType].Pax);
                            } else {
                                res.push(this.recordStore.getPaxQuantity(baseId, pType));
                            }
                        }
                    }
                }
                return res.join('; ');
            //}
            return res;
        },
        showTextSummary () {
            this.textSummary = true;
        },
        closeTextSummary () {
            this.textSummary = false;
        },
        async addTextSummary (text) {
            this.textSummary = false;
            this.$refs.summaryText.setValue(text, true);
            this.recordStore.Summary = text;
        },
        showInput () {
            if (!this.recordStore.CurrencyId || !this.recordStore.PriceCurrencyId) {
                alert(tr('Add Quote Currency'))
                return;
            }
            if (!this.recordStore.StartDate) {
                alert(tr('Add Start Date'))
                return;
            }
            if (!this.canEdit) return;
            this.showInputQuote = true;
        },
        async addBase (groups) {
            api.setProcessing(true);
            this.showInputQuote = false;
            if (this.firstQuote) {
                await this.recordStore.removeBase(this.firstQuote);
            }
            await this.recordStore.addBaseGroups(groups);
            this.firstQuote = this.getFirstQuote();
            this.quotePax = this.getQuotePax();
            api.setProcessing(false);
        },
        async removeBase () {
            await this.recordStore.removeBase(this.firstQuote);
            this.showInput();
        },
        currentTabEvent () {
            let self = this;
            this.change('Status');
            setTimeout(() => {
                $('#langTabs .nav-item span').on('click', function(event){
                    let x = $(event.target);
                    let x1;
                    if (!x[0].getAttribute('id')) {
                        x1 = event.target.parentNode;
                    } else {
                        x1 = x[0];
                    }
                    self.$store.commit('setCurrentTab', x1.getAttribute('id'));
                });
            }, 1000)
        },
        async change (fieldName) {
        }
    }
}
</script>

