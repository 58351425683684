<template>
          <div v-if="recordStore" :class="{'quote-container': recordStore.QuoteViewType=='ALL'}">
                <div class="card-body form-material px-0" :class="{'quote-list': recordStore.QuoteViewType=='ALL'}">
                    <div class="form-row col-md-12 mx-0 px-0">
                        <div class="col-md-1 section-title">
                            <label class="ml-3">{{tr('Services')}}</label>
                        </div>
                    </div>
                    <table class="table table-striped  table-sm table-bordered">
                        <thead>
                            <tr class="table-secondary" v-if="type=='PERSON'">
                                <th class="table-head-th th-day"></th>
                                <th class="table-head-th w-50"></th>
                                <th class="table-head-th col-value-xs" v-if="edit"></th>
                                <th class="table-head-th col-value">{{tr('Pax Cost')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('Mkp')}}</th>
                                <th class="table-head-th col-value">{{tr('Pax Price')}}</th>
                                <th class="table-head-th col-value">{{tr('#Pax')}}</th>
                            </tr>
                            <tr class="table-secondary" v-else-if="type=='ALL'">
                                <th class="table-head-th th-day"></th>
                                <th class="table-head-th mw-30"></th>
                                <th class="table-head-th col-value-xs" v-if="edit"></th>
                                <th class="table-head-th col-value">{{tr('Cost')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('Mkp')}}</th>
                                <th class="table-head-th col-value">{{tr('Price')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('Qty')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('#Pax')}}</th>
                                <th class="table-head-th th-cost-per">{{tr('Cost Per')}}</th>
                                <th class="table-head-th col-value">{{tr('Pax Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Pax Price')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Price')}}</th>
                            </tr>
                            <tr class="table-secondary" v-else-if="type=='SERVICE'">
                                <th class="table-head-th th-day"></th>
                                <th class="table-head-th mw-30"></th>
                                <th class="table-head-th col-value-xs" v-if="edit"></th>
                                <th class="table-head-th col-value">{{tr('Cost')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('Mkp')}}</th>
                                <th class="table-head-th col-value">{{tr('Price')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('Qty')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('#Pax')}}</th>
                                <th class="table-head-th th-cost-per">{{tr('Cost Per')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Price')}}</th>
                            </tr>
                        </thead>
                        <tbody v-if="show">
                            <booking-quote-table-service
                                v-for="(row, i) in serviceList"
                                :class="{'service-strong': focusDayId == row.dayId && i == focusService}"
                                :row="row"
                                :key="baseId + '-' + row.dayNr + '-' + row.sId + '-' + row.pType"
                                :i="i"
                                :paxTypesQuantity="paxTypesQuantity"
                                :baseId="baseId"
                                :type="recordStore.QuoteViewType"
                                :edit="edit"
                                @handleClick="handleClick"
                                @focusOn="focusOnService"
                            ></booking-quote-table-service>
                            <booking-quote-table-service-total
                                class="b-row"
                                :edit="edit"
                                pType="ADT"
                                :baseId="baseId"
                                :type="recordStore.QuoteViewType"
                                v-if="totalsServices && totalsServices[baseId] && recordStore.BaseQuotes[baseId].ADT">
                            ></booking-quote-table-service-total>
                            <booking-quote-table-service-total
                                :edit="edit"
                                pType="CHD"
                                :baseId="baseId"
                                :type="recordStore.QuoteViewType"
                                v-if="totalsServices && totalsServices[baseId] && recordStore.BaseQuotes[baseId].CHD">
                            ></booking-quote-table-service-total>
                            <booking-quote-table-service-total
                                :edit="edit"
                                pType="TL"
                                :baseId="baseId"
                                :type="recordStore.QuoteViewType"
                                v-if="totalsServices && totalsServices[baseId] && recordStore.BaseQuotes[baseId].TL">
                            ></booking-quote-table-service-total>
                        </tbody>
                    </table>

                    <table class="table table-striped  table-sm table-bordered mt-3">
                        <thead>
                            <tr class="table-secondary" v-if="type=='PERSON'">
                                <th class="table-head-th th-day"></th>
                                <th class="table-head-th w-50"></th>
                                <th class="table-head-th col-value-xs" v-if="edit"></th>
                                <th class="table-head-th col-value-sm">{{tr('#')}}</th>
                                <th class="table-head-th col-value">{{tr('#Pax/ Room')}}</th>
                                <th class="table-head-th col-value">{{tr('Cost/ Pax Stay')}}</th>
                                <th class="table-head-th col-value">{{tr('Mkp')}}</th>
                                <th class="table-head-th col-value">{{tr('Price/ Pax Stay')}}</th>
                                <th class="table-head-th col-value">{{tr('Cost / Room Night')}}</th>
                            </tr>
                            <tr class="table-secondary" v-else-if="type=='ALL'">
                                <th class="table-head-th th-day"></th>
                                <th class="table-head-th mw-30"></th>
                                <th class="table-head-th col-value-xs" v-if="edit"></th>
                                <th class="table-head-th col-value">{{tr('Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Mkp')}}</th>
                                <th class="table-head-th col-value">{{tr('Price')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('#')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('Pax')}}</th>
                                <th class="table-head-th col-value-md">{{tr('#Pax/ Room')}}</th>
                                <th class="table-head-th col-value">{{tr('Cost/ Pax Stay')}}</th>
                                <th class="table-head-th col-value">{{tr('Price/ Pax Stay')}}</th>
                                <th class="table-head-th col-value">{{tr('Pax Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Pax Price')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Price')}}</th>
                                <th class="table-head-th col-value">{{tr('Cost per')}}</th>
                            </tr>
                            <tr class="table-secondary" v-else>
                                <th class="table-head-th th-day"></th>
                                <th class="table-head-th mw-30"></th>
                                <th class="table-head-th col-value-xs" v-if="edit"></th>
                                <th class="table-head-th col-value">{{tr('Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Mkp')}}</th>
                                <th class="table-head-th col-value">{{tr('Price')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('#')}}</th>
                                <th class="table-head-th col-value-sm">{{tr('Pax')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Cost')}}</th>
                                <th class="table-head-th col-value">{{tr('Total Price')}}</th>
                                <th class="table-head-th col-value">{{tr('Cost per')}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <booking-quote-table-hotel
                                v-for="(row, i) in hotelList"
                                :class="{'service-strong': focusDayId == row.dayId && row.rId == focusHotel}"
                                :key="baseId + '-' + row.dayId + '-' + row.rId"
                                :ref="'room-' + baseId + '-' + row.dayId + '-' + row.rId"
                                :i="i"
                                :row="row"
                                :baseId="baseId"
                                :edit="edit"
                                :type="recordStore.QuoteViewType"
                                @handleClick="handleClick"
                                @editHotel="editHotel(row.dayNr, row.dayId)"
                                @focusOn="focusOnHotel"
                            ></booking-quote-table-hotel>
                            <booking-quote-table-hotel-total
                                pType="ADT"
                                :edit="edit"
                                :baseId="baseId"
                                :type="recordStore.QuoteViewType"
                                :totalsHotels="totalsHotels[baseId].ADT"
                                :baseTotalsHotels="baseTotalsHotels[baseId]"
                                v-if="totalsHotels && totalsHotels[baseId] && totalsHotels[baseId].ADT">
                            ></booking-quote-table-hotel-total>
                            <booking-quote-table-hotel-total
                                pType="CHD"
                                :edit="edit"
                                :baseId="baseId"
                                :type="recordStore.QuoteViewType"
                                :totalsHotels="totalsHotels[baseId].CHD"
                                v-if="totalsHotels && totalsHotels[baseId] && totalsHotels[baseId].CHD">
                            ></booking-quote-table-hotel-total>
                            <booking-quote-table-hotel-total
                                pType="TL"
                                :edit="edit"
                                :baseId="baseId"
                                :type="recordStore.QuoteViewType"
                                :totalsHotels="totalsHotels[baseId].TL"
                                v-if="totalsHotels && totalsHotels[baseId] && totalsHotels[baseId].TL">
                            ></booking-quote-table-hotel-total>
                        </tbody>
                    </table>

                    <booking-quote-flights
                        :baseId="baseId"
                        :edit="edit"
                    ></booking-quote-flights>
                    <div class="form-row col-md-12 mt-3">
                        <div class="col section-title">
                            <label class="ml-3">{{tr('Totals')}}</label>
                        </div>
                    </div>
                    <booking-quote-total-group
                        v-if="hasGroups"
                        :quote.sync="quote"
                        :baseId="baseId"
                    ></booking-quote-total-group>
                    <booking-quote-total-person
                        v-else-if="recordStore.QuoteViewType=='PERSON'"
                        :quote.sync="quote"
                        :baseId="baseId"
                    ></booking-quote-total-person>
                    <booking-quote-total-service
                        v-else-if="recordStore.QuoteViewType=='SERVICE'"
                        :quote.sync="quote"
                        :baseId="baseId"
                    ></booking-quote-total-service>
                    <booking-quote-total-all
                        v-else-if="recordStore.QuoteViewType=='ALL'"
                        :quote.sync="quote"
                        :baseId="baseId"
                    ></booking-quote-total-all>
                    <booking-quote-total
                        v-else
                        :quote.sync="quote"
                        :baseId="baseId"
                    ></booking-quote-total>
                </div>
                <div class="row mt-3 d-flex align-items-center">
                    <div class="offset-md-7 col-md-1">
                        <i class="quote-actions cursor-pointer" @click="edit = !edit" v-if="canEdit">
                              <font-awesome-icon v-if="edit" icon="edit" size="lg"/>
                              <font-awesome-icon v-else icon="pen" size="lg"/>
                         </i>
                    </div>
                    <div class="col-md-1 cursor-pointer">
                        <div v-if="canEdit">
                            <button @click="updateCostByDate" v-if="!updating" class="quote-actions" :disabled="!canRefreshQuote">
                                <font-awesome-icon icon="sync" size="lg"/>
                            </button>
                            <button v-else class="quote-actions">
                                <font-awesome-icon icon="sync" spin size="lg"/>
                            </button>
                        </div>
                    </div>
                    <div class="col-md-1">
                        <i class="quote-actions cursor-pointer" @click="copyBase" v-if="canCopy">
                            <font-awesome-icon icon="copy" size="lg"/>
                        </i>
                    </div>
                    <div class="col-md-1">
                        <i class="quote-actions cursor-pointer" @click="setGroups" v-if="canEdit">
                            <font-awesome-icon icon="users" size="lg" :class="{'icon-active': hasGroups}"/>
                        </i>
                    </div>
                    <div class="col-md-1" v-if="canEdit">
                        <i class="quote-actions cursor-pointer" @click="removeBase">
                            <font-awesome-icon icon="trash-alt" size="lg"/>
                        </i>
                    </div>
                </div>
                <hotel-selector-modal
                    v-if="hotelSelector"
                    :dayNr="currentDayNr"
                    :dayId="currentDayId"
                    @close="closeHotelSelector"
                    @delete="deleteHotel"
                    @removeRow="removeRow"
                ></hotel-selector-modal>
                <booking-quote-groups-table
                    :baseId="baseId"
                    :quoteGroups.sync="quoteGroups"
                    v-if="quoteGroups"
                ></booking-quote-groups-table>
                <booking-quote-rooming
                    :showRooming.sync="showRooming"
                    :baseId="baseId"
                    v-if="showRooming"
                ></booking-quote-rooming>

                <context-menu
                    v-if="showMenu"
                    :options="menuOptions"
                    :ref="'quoteMenu' + _uid"
                    @clicked="menuClicked"
                ></context-menu>

          </div>
</template>

<script>
const bookingQuoteFlights = importVueComp('components/booking', 'BookingQuoteFlights');
const hotelSelectorModal = importVueComp('components/hotel', 'HotelSelectorModal');
const bookingQuoteTotal = importVueComp('components/booking', 'BookingQuoteTotal');
const bookingQuoteTotalGroup = importVueComp('components/booking', 'BookingQuoteTotalGroup');
const bookingQuoteTotalPerson = importVueComp('components/booking', 'BookingQuoteTotalPerson');
const bookingQuoteTotalService = importVueComp('components/booking', 'BookingQuoteTotalService');
const bookingQuoteTotalAll = importVueComp('components/booking', 'BookingQuoteTotalAll');
const bookingQuoteTableService = importVueComp('components/booking', 'BookingQuoteTableService');
const bookingQuoteTableServiceTotal = importVueComp('components/booking', 'BookingQuoteTableServiceTotal');
const bookingQuoteTableHotel = importVueComp('components/booking', 'BookingQuoteTableHotel');
const bookingQuoteTableHotelTotal = importVueComp('components/booking', 'BookingQuoteTableHotelTotal');
const bookingQuoteGroupsTable = importVueComp('components/booking', 'BookingQuoteGroupsTable');
const bookingQuoteRooming = importVueComp('components/booking', 'BookingQuoteRooming');

import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote-table',
    props: ['quote', 'baseId'],
    components: {
        'booking-quote-flights': bookingQuoteFlights,
        'booking-quote-total': bookingQuoteTotal,
        'booking-quote-total-group': bookingQuoteTotalGroup,
        'booking-quote-total-person': bookingQuoteTotalPerson,
        'booking-quote-total-service': bookingQuoteTotalService,
        'booking-quote-total-all': bookingQuoteTotalAll,
        'hotel-selector-modal': hotelSelectorModal,
        'booking-quote-table-service': bookingQuoteTableService,
        'booking-quote-table-service-total': bookingQuoteTableServiceTotal,
        'booking-quote-table-hotel': bookingQuoteTableHotel,
        'booking-quote-table-hotel-total': bookingQuoteTableHotelTotal,
        'booking-quote-groups-table': bookingQuoteGroupsTable,
        'booking-quote-rooming': bookingQuoteRooming,
    },
    data () {
        return {
            edit: false,
            updating: null,
            hotelSelector: false,
            currentDay: null,
            totalsServices: {},
            totalsHotels: {},
            baseTotalsHotels: {},
            showRooming: null,
            //totals: {},
            currentDayId: null,
            currentDayNr: null,
            focusDayId: null,
            focusService: null,
            focusHotel: null,
            viewType: 'ALL',
            quoteGroups: false,
            show: true,
            hasGroups: false,
            menuOptions: [],
            defMenuOptions: [
                {label: tr('Deselect All'), name: 'deselectAll'},
                {label: tr('Select All'), name: 'selectAll'},
                {label: tr('Deselect Day'), name: 'deselectDay'},
                {label: tr('Select Day'), name: 'selectDay'},
            ],
            showMenu: false,
        }
    },
    mounted () {
        this.setTotals();
        EventBus.$on('update-totals', this.setTotals);
        EventBus.$on('update-service-table', this.refresh);
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
            managerActions: state => state.main.managerActions,
        }),
        paxTypesQuantity () {
            if (this.recordStore.BaseQuotes[this.baseId]) {
                let res = 1;
                let quote = this.recordStore.BaseQuotes[this.baseId];
                if (quote.CHD > 0) res += 1;
                if (quote.TL > 0) res += 1;
                return res;
            }
            return 1;
        },
        serviceList () {
            let res = [];
            let dayRowSpan = {};
            let c = 0;
            for (let dayId in this.recordStore.BookingDays) {
                let k = 0;
                dayRowSpan[dayId] = 0;
                let day = this.recordStore.BookingDays[dayId];
                for (let sId in day.BookingDayServices) {
                    let s = day.BookingDayServices[sId];
                    if (!this.showService(s)) continue;
                    let sk = 0;
                    for (let pType of this.recordStore.BaseQuotes[this.baseId].paxTypes) {
                        //console.log(s.Service.Name, pType, s.costPer, this.paxTypesQuantity)
                        let costPer = s.costPer;
                        if (s.Quote && s.Quote.QuoteList && s.Quote.QuoteList[this.baseId] && s.Quote.QuoteList[this.baseId][pType]) {
                            costPer = s.Quote.QuoteList[this.baseId][pType].costPer
                        }
                        if (costPer == 'SERVICE' && this.paxTypesQuantity > 1 && (pType == 'CHD' || pType == 'TL')) continue;
                        dayRowSpan[dayId] += 1;
                        if (pType == 'ADT') c += 1;
                        res.push({s, dayId, sId, dayNr: day.DayNr, date: day.TransDate, pType, k, sk, c});
                        k += 1;
                        sk += 1;
                    }
                }
            }
            let white = true;
            let lastDay;
            for (let row of res) {
                row.dayRowSpan = dayRowSpan[row.dayId];
                if (row.dayId != lastDay) {
                    white = !white;
                }
                row.rowWhite = white;
                lastDay = row.dayId;
            }
            return res;
        },
        hotelList () {
            let res = [];
            let days = {};
            for (let dayId in this.recordStore.BookingDays) {
                days[dayId] = 0;
                let k = 0;
                let day = this.recordStore.BookingDays[dayId];
                for (let hotel of day.BookingDayHotels) {
                    //hotel.forceCapacityGetter;
                    for (let rId in hotel.BookingDayRooms) {
                        let room = hotel.BookingDayRooms[rId];
                        if (!this.showRoom(room, day.DayNr)) continue;
                        days[dayId] += 1;
                        //console.log(dayId, rId, hotel.roomsCapacity[this.baseId], room.QuoteList[this.baseId].Groups)
                        //res.push({room, dayId, rId, dayNr: day.DayNr, date: day.TransDate, k, roomsCapacity: hotel.roomsCapacity[this.baseId]});
                        res.push({room, dayId, rId, dayNr: day.DayNr, date: day.TransDate, k});
                        k += 1;
                    }
                }
            }
            let white = true;
            let lastDay;
            for (let row of res) {
                row.rooms = days[row.dayId];
                if (row.dayId != lastDay) {
                    white = !white;
                }
                row.rowWhite = white;
                lastDay = row.dayId;
            }
            return res;
        },
        type () {
            return this.recordStore.QuoteViewType;
        },
        canEdit () {
            return this.salesActions;
        },
        canCopy () {
            return this.canEdit;
        },
        canRefreshQuote () {
            return this.canEdit;
        },
        hotelsByDay () {
            return this.recordStore.hotelsByDay;
        },
        /*hasGroups () {
            return this.recordStore.QuoteGroups[this.baseId];
        }*/
    },
    methods: {
        refresh () {
            //this.show = false;
            //setTimeout(() => {this.show = true}, 1);
        },
        /*showServices (day) {
            let found = false;
            for (let s of day.BookingDayServices) {
                if (s.Service && s.Service.NotPrice) continue;
                if (s.Quote.QuoteList && s.Quote.QuoteList[this.baseId]) {
                    for (let p in s.Quote.QuoteList[this.baseId]) {
                        if (s.costPer == 'SERVICE' && this.paxTypesQuantity > 1 && p == 'CHD') continue;
                        if (s.Quote.QuoteList[this.baseId][p] && s.Quote.QuoteList[this.baseId][p].Include) found = true;
                    }
                }
            }
            if (found || this.edit) {
                return true;
            }
            return false;
        },*/
        showService (s) {
            if (s.Service && s.Service.NotPrice) return;
            if (this.edit) return true;
            let found = false;
            if (s.Quote.QuoteList && s.Quote.QuoteList[this.baseId]) {
                for (let p in s.Quote.QuoteList[this.baseId]) {
                    //if (s.costPer == 'SERVICE' && this.paxTypesQuantity > 1 && p == 'CHD') continue;
                    if (s.Quote.QuoteList[this.baseId][p] && s.Quote.QuoteList[this.baseId][p].Include) found = true;
                }
            }
            if (found) return true;
            return false;
        },

        setTotals () {
            this.totalsServices = Object.assign({}, this.recordStore.totalsServices);
            this.totalsHotels = Object.assign({}, this.recordStore.totalsHotels);
            //this.totals = Object.assign({}, this.recordStore.totals);
            this.baseTotalsHotels = Object.assign({}, this.recordStore.baseTotalsHotels);
            //EventBus.$emit('update-summary');
            this.hasGroups = this.recordStore.isGroup(this.baseId);
        },
        async updateCostByDate () {
            let selectValue = {
                text: 'Update cost edited manually?',
                yes: async () => {
                    api.setProcessing(true);
                    await this.recordStore.setCostByDate({update: true, baseId: this.baseId, updateModified: 1});
                    EventBus.$emit('update-totals');
                },
                not: async () => {
                    api.setProcessing(true);
                    await this.recordStore.setCostByDate({update: true, baseId: this.baseId});
                    EventBus.$emit('update-totals');
                }
            }
            this.$root.$children[0].openSelectYesNot(selectValue);
        },
        removeBase () {
            this.$emit('remove', this.baseId);
        },
        copyBase () {
            this.$emit('copy', this.baseId);
        },
        removeRow () {
            //this.setTotals();
            //console.log('removeRow')
            EventBus.$emit('update-quotes-hotels');
        },
        hasPayments (dayNr) {
            let hotelInfo = this.hotelsByDay[dayNr];
            for (let room of hotelInfo.BookingDayRooms) {
                let supplierId;
                if (room.Hotel && room.Hotel.SupplierId) supplierId = room.Hotel.SupplierId;
                if (!supplierId) continue;
                let payments = _.filter(api.payments, (p) => p.SupplierId == supplierId);
                if (payments.length > 0) {
                    let res = confirm(tr('Attention! This provider has payments in the file. Before make changes, edit or remove payments. Do you want to continue?'));
                    if (!res) return false;
                }
            }
            return true
        },
        editHotel (dayNr, dayId) {
            if (!this.hasPayments(dayNr)) return;
            this.currentDayId = dayId;
            this.currentDaNr = dayNr;
            this.hotelSelector = true;
        },
        async deleteHotel () {
            await this.recordStore.BookingDays[this.currentDayId].deleteHotel()
            EventBus.$emit('update-totals');
            this.hotelSelector = false;
            this.currentDayId = null;
            this.currentDayNr = null;
        },
        closeHotelSelector () {
            this.hotelSelector = false;
            this.currentDayId = null;
            this.currentDayNr = null;
        },
        showRoom (roomRow, dayNr) {
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && roomRow.QuoteList[this.baseId] && (this.edit || roomRow.QuoteList[this.baseId].Include)) return true;
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && !roomRow.QuoteList[this.baseId]) return true;
        },
        focusOnService (dayId, i) {
            this.focusDayId = dayId;
            this.focusService = i;
            this.focusHotel = null;
        },
        focusOnHotel (dayId, i) {
            this.focusDayId = dayId;
            this.focusService = null;
            this.focusHotel = i;
        },
        setGroups () {
            this.quoteGroups = true;
        },
        async handleClick (event, dayId, type) {
            if (!this.edit) return;
            event.preventDefault();
            this.showMenu = true;
            this.menuOptions = Object.assign([], this.defMenuOptions);
            if (type == 'h') {
                let categories = this.recordStore._hotelCategories;
                if (Object.keys(categories).length > 1) {
                    for (let id in categories) {
                        this.menuOptions.push({name: 'cat_' + id, label: 'Hotel Category ' + categories[id]})
                    }
                }
            }
            await this.$nextTick();
            setTimeout(() => {
                let id = `quoteMenu${this._uid}`
                if (this.$refs[id]) {
                    this.$refs[id].showMenu(event, {dayId, type});
                }
            }, 100);
        },
        setSelectedServices (day, value) {
            for (let service of day.BookingDayServices) {
                if (!service.Quote) continue;
                if (!service.Quote.QuoteList) continue;
                if (!service.Quote.QuoteList[this.baseId]) continue;
                for (let pType of this.recordStore.BaseQuotes[this.baseId].paxTypes) {
                    if (!service.Quote.QuoteList[this.baseId][pType]) continue;
                    service.Quote.QuoteList[this.baseId][pType].Include = value;
                }
            }
        },
        setSelectedRooms (day, value, catId) {
            for (let hotel of day.BookingDayHotels) {
                for (let room of hotel.BookingDayRooms) {
                    if (!room.QuoteList) continue;
                    if (!room.QuoteList[this.baseId]) continue;
                    if (value && catId) {
                        if (room.HotelCategoryId == catId) {
                            room.QuoteList[this.baseId].Include = true;
                        } else {
                            room.QuoteList[this.baseId].Include = false;
                        }
                    } else {
                        room.QuoteList[this.baseId].Include = value;
                    }
                }
            }
        },
        menuClicked (option, {dayId, type}) {
            if (option.name == 'deselectAll') {
                for (let day of this.recordStore.BookingDays) {
                    if (type == 's') {
                        this.setSelectedServices(day, false);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(day, false);
                    }
                }
            }
            if (option.name == 'selectAll') {
                for (let day of this.recordStore.BookingDays) {
                    if (type == 's') {
                        this.setSelectedServices(day, true);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(day, true);
                    }
                }
            }
            if (option.name == 'selectDay') {
                if (this.recordStore.BookingDays[dayId]) {
                    if (type == 's') {
                        this.setSelectedServices(this.recordStore.BookingDays[dayId], true);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(this.recordStore.BookingDays[dayId], true);
                    }
                }
            }
            if (option.name == 'deselectDay') {
                if (this.recordStore.BookingDays[dayId]) {
                    if (type == 's') {
                        this.setSelectedServices(this.recordStore.BookingDays[dayId], false);
                    }
                    if (type == 'h') {
                        this.setSelectedRooms(this.recordStore.BookingDays[dayId], false);
                    }
                }
            }
            if (type == 'h' && option.name.substring(0,3) == 'cat') {
                api.setProcessing(true)
                let catId = parseInt(option.name.split('_')[1]);
                for (let day of this.recordStore.BookingDays) {
                    this.setSelectedRooms(day, true, catId);
                }
                api.setProcessing(false)
            }
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        }

    },
    beforeDestroy() {
        EventBus.$off('update-totals', this.setTotals);
        EventBus.$off('update-service-table', this.refresh);
    },
}
</script>

<style scoped>
 th, td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.25rem!important;
    padding-right: 0.25rem!important;
 }

</style>


