<template>
    <div class="d-flex flex-row ml-3" v-if="ready">
        <div class="d-flex flex-row mr-3" v-for="(room, i) of roomTypes">
            <div class="">
                <i class="add-item cursor-pointer m-1" @click="add(i)" :class="{'disabled': !edit}">
                    <font-awesome-icon icon="plus-circle"/>
                </i>
                <i class="add-item cursor-pointer mr-2" @click="remove(i)"  :class="{'disabled': !edit}">
                    <font-awesome-icon icon="minus-circle"/>
                </i>
            </div>
            <span class="font-wieght-bold"><b>{{ room.Name }}: {{ rooms[i] }} </b></span>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    name: 'booking-quote-groups-rooms',
    props: ['roomsQty', 'edit', 'defRooming'],
    data () {
        return {
            rooms: {},
            ready: false
        }
    },
    async mounted () {
        for (let roomId in this.roomTypes) {
            if (this.defRooming && this.defRooming[roomId]) {
                this.rooms[roomId] = this.defRooming[roomId];
            } else {
                this.rooms[roomId] = 0;
            }
        }
        this.ready = true;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        roomTypes () {
            return this.recordStore.roomTypesObjects;
        }
    },
    methods: {  
        add (i) {
            if (!this.edit) return;
            this.rooms[i] += 1;
            this.rooms = Object.assign({}, this.rooms)
            this.$emit('update:roomsQty', this.rooms)
        },
        remove (i) {
            if (!this.edit) return;
            if (this.rooms[i] == 0) return;
            this.rooms[i] += -1;
            this.rooms = Object.assign({}, this.rooms)
            this.$emit('update:roomsQty', this.rooms)
        }
    },
}
</script>


<style scoped>
.disabled {
    color: grey;
}
</style>