<template>
    <tr v-if="totalsHotels">
        <td class="p-0 th-day"></td>
        <td class="p-0" :class="{'w-50': type=='PERSON', 'mw-30': type!='PERSON'}"><strong>
            {{tr('Total')}} <span v-if="paxTypes == 2">{{pType}}</span>
        </strong></td>
        <td class="p-0 col-value-xs" v-if="edit"></td>
        <td class="col-value" v-if="type!='PERSON'"></td>
        <td class="p-0 col-value" v-if="type!='PERSON'"></td>
        <td class="col-value" v-if="type!='PERSON'"></td>
        <td class="p-0 col-value-sm"></td>
        <td class="p-0 col-value-sm" v-if="type!='PERSON'">
        </td>
        <td class="p-0 col-value" v-if="type=='ALL'">
        </td>
        <td class="p-0 col-value" v-if="type=='ALL'">
        </td>
        <td class="p-0 col-value" v-if="type!='SERVICE'">
        </td>
        <td class="col-value disabled" v-if="type!='SERVICE'">
            <number-input
                class="my-0 mx-0"
                :v="totalsHotels.PersonCost"
                :changeStyle="true"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value" v-if="type=='PERSON'"></td>
        <td class="col-value disabled" v-if="type!='SERVICE'">
            <number-input
                class="my-0 mx-0"
                :v="totalsHotels.PersonPrice"
                :changeStyle="true"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value disabled" v-if="type!='PERSON'">
            <number-input
                v-if="pType=='ADT'"
                class="my-0 mx-0"
                :v="baseTotalsHotels.Cost"
                :changeStyle="true"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value disabled"  v-if="type!='PERSON'">
            <number-input
                v-if="pType=='ADT'"
                class="my-0 mx-0"
                :v="baseTotalsHotels.Price"
                :changeStyle="true"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value"></td>
    </tr>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote-table-hotel-total',
    props: ['totalsHotels', 'baseId', 'pType', 'type', 'edit', 'baseTotalsHotels'],
    mounted () {
        //EventBus.$on('update-hotel-totals', this.setTotals);
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            user: state => state.main.user,
        }),
        paxTypes () {
            if (this.recordStore.BaseQuotes[this.baseId]) {
                let quote = this.recordStore.BaseQuotes[this.baseId];
                let c = 1;
                if (quote.TL > 0) c += 1;
                if (quote.CHD > 0) c += 1;
                return c;
            }
        },
    },
    beforeDestroy() {
        //EventBus.$off('update-hotel-totals', this.setTotals);
    },
}
</script>

