<template>
    <tr v-if="totalsServices">
        <td class="p-0 th-day"></td>
        <td class="p-0 mw-30"><strong>
            {{tr('Total')}} <span v-if="paxTypes == 2">{{pType}}</span>
        </strong></td>
        <td class="p-0 col-value-xs" v-if="edit"></td>
        <td class="col-value" v-if="type!='PERSON'"></td>
        <td class="p-0 col-value-sm" v-if="type!='PERSON'"></td>
        <td class="col-value" v-if="type!='PERSON'"></td>
        <td class="p-0 col-value-sm" v-if="type!='PERSON'"></td>
        <td class="p-0 col-value-sm" v-if="type!='PERSON'"></td>
        <td class="th-cost-per" v-if="type!='PERSON'"></td>
        <td class="p-0 col-value disabled" v-if="type!='SERVICE'">
            <number-input
                class="my-0 mx-0"
                :changeStyle="true"
                :v="totalsServices[pType].PersonCost"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value" v-if="type=='PERSON'"></td>
        <td class="p-0 col-value disabled" v-if="type!='SERVICE'">
            <number-input
                class="my-0 mx-0"
                :changeStyle="true"
                :v="totalsServices[pType].PersonPrice"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value text-left" v-if="type=='PERSON'">
        </td>
        <td class="col-value disabled" v-if="type!='PERSON'">
            <number-input
                v-if="pType=='ADT'"
                :changeStyle="true"
                class="my-0 mx-0"
                :v="totalServiceCost"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
        <td class="col-value disabled"  v-if="type!='PERSON'">
            <number-input
                v-if="pType=='ADT'"
                :changeStyle="true"
                class="my-0 mx-0"
                :v="totalServicePrice"
                disabled="true" :currency="recordStore.Currency"
            ></number-input>
        </td>
    </tr>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-quote-table-service-total',
    props: ['baseId', 'pType', 'type', 'edit'],
    data () {
        return {
            totalsServices: null,
        }
    },
    mounted () {
        this.setTotals();
        EventBus.$on('update-totals', this.setTotals);
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
            user: state => state.main.user,
        }),
        paxTypes () {
            if (this.recordStore.BaseQuotes[this.baseId]) {
                let quote = this.recordStore.BaseQuotes[this.baseId];
                let c = 1;
                if (quote.TL > 0) c += 1;
                if (quote.CHD > 0) c += 1;
                return c;
                }
        },
        totalServicePrice () {
            if (!this.totalsServices) return;
            if (!this.totalsServices.ADT) return;
            let res = this.totalsServices.ADT.Price;
            if (this.totalsServices.CHD && this.totalsServices.CHD.Price) {
                res += this.totalsServices.CHD.Price
            };
            if (this.totalsServices.TL && this.totalsServices.TL.Price) {
                res += this.totalsServices.TL.Price
            };
            return res;
        },
        totalServiceCost () {
            if (!this.totalsServices) return;
            if (!this.totalsServices.ADT) return;
            let res = this.totalsServices.ADT.Cost;
            if (this.totalsServices.CHD && this.totalsServices.CHD.Cost) {
                res += this.totalsServices.CHD.Cost
            };
            if (this.totalsServices.TL && this.totalsServices.TL.Cost) {
                res += this.totalsServices.TL.Cost
            };
            return res;
        },
    },
    methods: {
        setTotals () {
            this.totalsServices = Object.assign({}, this.recordStore.totalsServices[this.baseId]);
        },

    },
    beforeDestroy() {
        EventBus.$off('update-totals', this.setTotals);
    },
}
</script>


<style scoped>
 td {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0.25rem!important;
    padding-right: 0.25rem!important;
 }
</style>

