
const booking = {
    state: {
        currentTab: null,
        bookingForm: {},
        serviceCost: {},
        hotelCost: [],
        costValues: {},
        hotelValues: {},
        pinText: null,
    },
    mutations: {
        setCurrentTab (state, value) {
            state.currentTab = value;
        },
        setBookingForm (state, value) {
            state.bookingForm = Object.assign({}, value);
        },
        setServiceCostId (state, {id, categoryId}) {
            state.serviceCost[[id, categoryId]] = [];
        },
        pushServiceCostId (state, {id, categoryId, value}) {
            state.serviceCost[[id, categoryId]].push(value);
        },
        setCostByBase (state , {costValue, base, pType, id, serviceDate, categoryId}) {
            if (!state.costValues[[id, categoryId]]) {
                state.costValues[[id, categoryId]] = {};
            }
            if (!state.costValues[[id, categoryId]][serviceDate]) {
                state.costValues[[id, categoryId]][serviceDate] = {}
            }
            if (!state.costValues[[id, categoryId]][serviceDate][base]) {
                state.costValues[[id, categoryId]][serviceDate][base] = {}
            }
            state.costValues[[id, categoryId]][serviceDate][base][pType] = costValue;
        },
        pushHotelCost (state, cost) {
            state.hotelCost.push(cost);
        },
        setCostForRoomByBase (state, {costValue, base, roomId, roomCategoryId, hotelDate, hotelId, days}){
            if (!state.hotelValues[hotelId]) state.hotelValues[hotelId] = {};
            if (!state.hotelValues[hotelId][roomId]) state.hotelValues[hotelId][roomId] = {};
            if (!state.hotelValues[hotelId][roomId][roomCategoryId]) state.hotelValues[hotelId][roomId][roomCategoryId] = {};
            if (!state.hotelValues[hotelId][roomId][roomCategoryId][hotelDate]) state.hotelValues[hotelId][roomId][roomCategoryId][hotelDate] = {};
            if (!state.hotelValues[hotelId][roomId][roomCategoryId][hotelDate][days]) state.hotelValues[hotelId][roomId][roomCategoryId][hotelDate][days] = {};
            if (!state.hotelValues[hotelId][roomId][roomCategoryId][hotelDate][days][base]) state.hotelValues[hotelId][roomId][roomCategoryId][hotelDate][days][base] = costValue;
        },
        setPinText (state, value) {
            state.pinText = Object.assign({}, value);
        }

    },
    actions: {
        async setBookingForm ({commit, state}, {bookingId}) {
            let res = await api.get('/api/bookingform/bybookingid/' + bookingId)
            if (res) {
                commit('setBookingForm', res);
            }
        },
        async setBookingCost ({commit, state}, {booking}) {
            for (let i=0; i<booking.BookingDays.length; i++) {
                let day = booking.BookingDays[i];
                let quoteDate = day.TransDate;
                if (booking.SplitQuoteDate && booking.QuoteDate) {
                    quoteDate = day.QuoteDate;
                }
                for (let s of day.BookingDayServices) {
                    if (!s.Service) continue;
                    this.dispatch('setCostForService', {booking, s , quoteDate});
                }
                if (day.BookingDayHotels[0] && day.BookingDayHotels[0].BookingDayRooms) {
                    for (let room of day.BookingDayHotels[0].BookingDayRooms) {
                        if (!room.QuoteList) continue
                        if (!room.Hotel) continue
                        let hotelDate = day.TransDate;
                        if (booking.SplitQuoteDate && booking.QuoteDate) {
                            hotelDate = day.QuoteDate;
                        }
                        this.dispatch('setCostForRoom', {
                            booking,
                            room,
                            idx: i,
                            hotelId: room.Hotel.id,
                            hotelDate: hotelDate,
                            days: day.BookingDayHotels[0].Days,
                            program: room && room.Category && room.Category.Program,
                            hasProgramRooms: booking.hasProgramRooms(room.Hotel),
                            currencyId: room.CurrencyId,
                        })
                    }
                }
            }
        },
        async setCostForRoom ({commit, state}, {booking, room, idx, hotelId, hotelDate, days, program, hasProgramRooms, currencyId}) {
            let cost = {}
            for (let i=0; i<days; i++) {
                let date = moment(hotelDate).add(i,'days').format("YYYY-MM-DD");
                let costByDate;
                if (costByDate) {
                    cost[i] = costByDate;
                } else {
                    let res = await api.getHotelCost(hotelId, date, program, currencyId);
                    if (res) {
                        commit('pushHotelCost', {cost});
                        cost[i] = res;
                    } else {
                        cost[i] = null;
                    }
                }
            }
            let totalNights = booking.getHotelTotalNights(hotelId);
            let CurrencyId;
            if (Object.keys(cost).length>0) {
                for (let i=0; i<Object.keys(cost).length; i++) {
                    if (cost[i]) {
                        CurrencyId = cost[i].CurrencyId;
                        break;
                    }
                }
             } else {
                CurrencyId = booking.CurrencyId;
            }
            if (booking.BookingDays[idx].BookingDayHotels[0]) {
              for (let i in booking.BookingDays[idx].BookingDayHotels[0].BookingDayRooms) {
                let room = booking.BookingDays[idx].BookingDayHotels[0].BookingDayRooms[i];
                if (room.HotelId!=hotelId) continue;
                let roomCategoryId = room.RoomCategory;
                if (room.Room) {
                    let roomTypes = await api.getTable('roomtype');
                    //let commission = booking.getHotelCommission(room);
                    let roomCost = booking.getRoomCost(cost, room, roomCategoryId, days,
                        booking.CurrencyId, booking.currencyRates,
                        roomTypes, totalNights, booking.QuoteBy);
                    for (let base in booking.BaseQuotes.bases) {
                        if (!room.QuoteList[base]) continue
                        let quote = room.QuoteList[base];
                        if (!quote) continue;
                        let costByRoom = parseFloat(roomCost.Cost) * quote.roomPax / parseInt(days) * quote.qFactor;
                        let costValue = quote.getCost();
                        commit('setCostForRoomByBase', {costValue, base, roomId: room.Room.id,
                            roomCategoryId, hotelDate, hotelId: room.HotelId, days});
                    }
                }
              }
            }
        },
        async setCostForService ({commit, state}, {booking, s, quoteDate}) {
            let quoteData = s.Quote;
            let cost = await this.dispatch('getServiceCostFromState', {id: s.ServiceId , serviceDate: quoteDate,
              categoryId: s.Service.ServiceCategoryId});
            if (cost) {
                for (let base in booking.BaseQuotes.bases) {
                    for (let pType of booking.BaseQuotes[base].paxTypes) {
                        if (!quoteData.QuoteList[base]) continue;
                        if (!quoteData.QuoteList[base][pType]) continue;
                        let paxBase;
                        paxBase = quoteData.QuoteList[base][pType].Base;
                        let pax = parseInt(booking.BaseQuotes[base].ADT) + parseInt(booking.BaseQuotes[base].CHD);
                        if (paxBase) pax = parseInt(quoteData.QuoteList[base][pType].Base);
                        let commission = booking.getServiceCommission(s);
                        let costForBase = travelTools.getCostForBase(cost, pax, pType, commission, booking.QuoteBy, s.ExtraSeat, s.ADTCostCHD);
                        let costValue = costForBase.cost;
                        if (costValue) {
                            costValue = booking.currencyConvert(cost.CurrencyId, booking.CurrencyId, costValue);
                            commit('setCostByBase', {costValue, base, pType, id: s.ServiceId, serviceDate: s.ServiceDate,
                                categoryId: s.Service.ServiceCategoryId});
                        }
                    }
                }
            }
        },
        async getServiceCostFromState ({commit, state, getters}, {id, serviceDate, categoryId}) {
            if (!serviceDate) return;
            let serviceCost = state.serviceCost[[id, categoryId]];
            let cost = null;
            if (serviceCost){
                for (let period of serviceCost){
                    if (serviceDate>=period.StartDate && serviceDate<=period.EndDate) {
                        if (!cost) {
                            cost = _.cloneDeep(period);
                        } else {
                            if (period.StartDate>=cost.StartDate && period.EndDate<=cost.EndDate) {
                                cost = _.cloneDeep(period);
                            }
                        }
                    }
                }
            } else {
                commit('setServiceCostId', {id, categoryId});
            }
            if (!cost) {
                let value = await api.getServiceCost(id, serviceDate, categoryId);
                if (value) {
                    commit('pushServiceCostId', {id, categoryId, value});
                    cost = value;
                }
            }
            return cost;
        },

    }
}

export default booking;
