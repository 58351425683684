<template>
    <div class="modal fade bd-example-modal-lg" id="booking-quote-groups" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="booking-quote-groups" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-auto modal-large" role="document">
        <div class="modal-content">
          <div class="modal-header">
              <h5 class="modal-title">{{tr('Passenger Groups')}}:
                  {{getPaxByBase}}
              </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close" :disabled="!checkPax" >
                <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <booking-quote-groups 
                class="modal-header form-material"
                :groups.sync="groups"
                :edit="edit"
                :ready="ready"
                :baseId="baseId"
                ref="paxGruops"
                @setEdit="edit = true"
                @removeClose="removeClose"
                @save="save"
                @cancel="cancel"
          ></booking-quote-groups>
          <div class="modal-body form-material"  v-if="saved">
              <table class="table table-striped  table-sm table-bordered" id="table-groups">
                  <thead>
                      <tr class="table-secondary">
                          <th class="table-head-th group-day">{{tr('Day')}}</th>
                          <th class="table-head-th group-service">{{tr('Service')}}</th>
                          <th class="table-head-th group-sm">{{tr('Pax')}}</th>
                          <th class="table-head-th group-value" v-for="(g, k) of storeGroups">
                              {{g.Name || '#' + (parseInt(k) + 1)}}
                          </th>
                      </tr>
                  </thead>
                  <tbody>
                      <tr v-for="(row, i) in serviceList" :class="{'bg-white': row.rowWhite, 'bg-white-blue': !row.rowWhite}">
                          <td v-if="row.k == 0" :rowspan="row.services" class="group-day">
                              <div class="text-center">
                                  <span class="text-nowrap">{{date(row.date)}}</span>&nbsp
                                  <span class="text-nowrap">{{tr('Day')}} {{row.dayNr+1}}</span>
                              </div>
                          </td>
                          <td class="text-left px-1 group-service">
                              {{row.s.Name || row.s.Service.Name}}
                          </td>
                          <td class="group-sm">
                              {{row.s.Quote.QuoteList[baseId].paxQuant}}
                          </td>
                          <td class="group-value" v-for="(g, groupId) of storeGroups" v-if="ready">
                              <input v-model="row.s.Quote.QuoteList[baseId].Groups[groupId]" type="checkbox"
                                :disabled="edit" @change="sumGroup(row.dayId, 'services')"/>
                          </td>
                      </tr>
                      <tr class="table-secondary">
                          <th class="table-head-th group-day"></th>
                          <th class="table-head-th group-service">{{tr('Hotels')}}</th>
                          <th class="table-head-th group-sm" @click="changeView">
                            {{roomsViewOptions[roomsView]}}
                         </th>
                          <th class="table-head-th group-value" v-for="g of storeGroups"></th>
                      </tr>
                      <tr v-for="(row, i) in hotelList" :class="{'bg-white': row.rowWhite, 'bg-white-blue': !row.rowWhite}">
                          <td v-if="row.k == 0" :rowspan="row.rooms" class="group-day">
                              <div class="text-center">
                                  <span class="text-nowrap">{{date(row.date)}}</span>&nbsp
                                  <span class="text-nowrap">{{tr('Day')}} {{row.dayNr+1}}</span>
                              </div>
                          </td>
                          <td class="text-left px-1 group-service" >
                              {{row.room.Hotel.Name}} {{tr('Nights')}} {{row.room.QuoteList[baseId].Rooms}} {{row.room.Room.Name}}
                              {{row.room.Category.Name}}
                          </td>
                          <td class="group-sm">
                            <span v-if="row.room.QuoteList[baseId]">
                                {{row.room.QuoteList[baseId].Pax}}
                            </span>      
                          </td>
                          <!--td class="group-sm">
                            <span v-if="row.room.QuoteList[baseId]">
                                {{row.room.QuoteList[baseId].Rooms}}
                            </span>      
                          </td-->
                          <td class="group-value" v-for="(g, groupId) of storeGroups" v-if="ready && row.room.QuoteList[baseId]">
                                <!--input v-model="row.room.QuoteList[baseId].Groups[groupId]" type="checkbox" class="rooms-input text-right"
                                  v-if="row.room.QuoteList[baseId].Groups && isBoolean(row.room.QuoteList[baseId].Groups)"
                                  :disabled="edit" @change="sumGroup(row.dayId, 'hotels')"/-->
                                  <input v-model="row.room.QuoteList[baseId].Groups[groupId].selected" type="checkbox" class="rooms-input text-right"
                                  :disabled="edit" @change="sumGroup(row.dayId, 'hotels')" v-if="roomsView==0"/>
                                  <number-input
                                    v-else-if="roomsView==1 && row.room.QuoteList[baseId].Groups[groupId].selected"
                                    class="my-0"
                                    :v.sync="row.room.QuoteList[baseId].Groups[groupId].pax"
                                    :d="0"
                                    addClass=""
                                    :hideLabel="true"
                                    @change="sumGroup(row.dayId, 'hotels')"
                                ></number-input>
                                <number-input
                                    v-else-if="roomsView==2 && row.room.QuoteList[baseId].Groups[groupId].selected"
                                    class="my-0"
                                    :v.sync="row.room.QuoteList[baseId].Groups[groupId].rooms"
                                    :hideLabel="true"
                                    :d="0"
                                    @change="sumGroup(row.dayId, 'hotels')"
                                ></number-input>
                                  <div v-else-if="roomsView==3 && row.room.QuoteList[baseId].Groups[groupId].selected" class="d-flex">
                                   <span v-if="row.room.QuoteList[baseId].Groups[groupId].pax" class="text-center">
                                        {{ row.room.QuoteList[baseId].Groups[groupId].pax }} in 
                                        {{ row.room.QuoteList[baseId].Groups[groupId].rooms}} rooms
                                   </span>
                                </div>
                          </td>
                      </tr>
                      <tr class="table-secondary">
                          <th class="table-head-th" colspan="4">{{tr('Flights')}}</th>
                          <th class="table-head-th" v-for="g of storeGroups"></th>
                      </tr>
                      <tr v-for="(flight, i) in recordStore.BookingFlights">
                          <td class="text-left px-1" colspan="3">
                              {{flight.flightRoute}}
                          </td>
                          <td class="group-value" v-for="(g, groupId) of storeGroups" v-if="ready">
                              <input v-model="flight.QuoteList[baseId].Groups[groupId]" type="checkbox"
                                  v-if="flight.QuoteList[baseId].Groups"
                                  :disabled="edit" @change="sumGroup(null, 'flights')"/>
                          </td>
                      </tr>
                  </tbody>
                  <thead>
                      <tr>
                          <td class="group-day">
                          </td>
                          <td class="text-center px-1 group-service">
                              <b>{{tr('Total per Pax')}} ADT</b>
                          </td>
                          <td class="group-sm">
                          </td>
                          <td class="text-right group-value" v-for="(g, groupId) of storeGroups" v-if="ready">
                              <b v-if="totalByPaxGroup[groupId]">{{totalByPaxGroup[groupId].ADT.Price | formatNumber({c: recordStore.PriceCurrency, d: 0})}}</b>
                          </td>
                      </tr>
                      <tr v-if="hasChild">
                          <td class="group-day">
                          </td>
                          <td class="text-center group-service">
                              <b>{{tr('Total per Pax')}} CHD</b>
                          </td>
                          <td class="group-sm">
                          </td>
                          <td class="text-right group-value" v-for="(g, groupId) of storeGroups" v-if="ready">
                              <b v-if="totalByPaxGroup[groupId]">{{totalByPaxGroup[groupId].CHD.Price  | formatNumber({c: recordStore.PriceCurrency, d: 0})}}</b>
                          </td>
                      </tr>
                      <tr v-if="hasTL">
                          <td class="group-day">
                          </td>
                          <td class="text-center group-service">
                              <b>{{tr('Total per Pax')}} TL</b>
                          </td>
                          <td class="group-sm">
                          </td>
                          <td class="text-right group-value" v-for="(g, groupId) of storeGroups" v-if="ready">
                              <b v-if="totalByPaxGroup[groupId]">{{totalByPaxGroup[groupId].TL.Price  | formatNumber({c: recordStore.PriceCurrency, d: 0})}}</b>
                          </td>
                      </tr>
                  </thead>

              </table>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
const bookingQuoteGroups = importVueComp('components/booking', 'BookingQuoteGroups');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'booking-quote-groups-table',
    mixins: [escape],
    props: ['quoteGroups', 'baseId'],
    components: {
        'booking-quote-groups': bookingQuoteGroups,
    },
    data () {
        return {
            groups: {},
            ready: false,
            edit: false,
            totalByPaxGroup: {},
            sumUpGroups: null,
            saved: false,
            refresh: true,
            roomsView: 0,
            roomsViewOptions: {
                0: 'Select',
                1: 'Pax',
                2: 'Rooms',
                3: 'P/R'
            }
        }
    },
    async mounted () {
        $('#booking-quote-groups').modal({backdrop: 'static', keyboard: false}, 'show');
        if (this.recordStore.QuoteGroups[this.baseId]) {
            //this.groups = Object.assign({}, this.recordStore.QuoteGroups[this.baseId]);
            this.refresGroup()
            this.updateTotals();
            this.saved = true;
            this.ready = true;
        }
        /*if (!this.groups || Object.keys(this.groups).length == 0) {
            this.addGroup();
            this.updateTotals();
            this.ready = true;
        }*/
        window._booking = this.recordStore;
        this.fixTable();
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        storeGroups () {
            return this.recordStore.QuoteGroups[this.baseId];
        },
        serviceList () {
            this.refresh;
            let res = [];
            let days = {};
            for (let dayId in this.recordStore.BookingDays) {
                days[dayId] = 0;
                let k = 0;
                let day = this.recordStore.BookingDays[dayId];
                for (let sId in day.BookingDayServices) {
                    let s = day.BookingDayServices[sId];
                    if (!this.showService(s)) continue;
                    days[dayId] += 1;
                    res.push({s, dayId, sId, dayNr: day.DayNr, date: day.TransDate, k});
                    k += 1;
                }
            }
            let white = true;
            let lastDay;
            for (let row of res) {
                row.services = days[row.dayId];
                if (row.dayId != lastDay) {
                    white = !white;
                }
                row.rowWhite = white;
                lastDay = row.dayId;
            }
            return res;
        },
        hotelList () {
            this.refresh;
            let res = [];
            let days = {};
            for (let dayId in this.recordStore.BookingDays) {
                days[dayId] = 0;
                let k = 0;
                let day = this.recordStore.BookingDays[dayId];
                for (let hotel of day.BookingDayHotels) {
                    for (let rId in hotel.BookingDayRooms) {
                        let room = hotel.BookingDayRooms[rId];
                        if (!this.showRoom(room, day.DayNr)) continue;
                        days[dayId] += 1;
                        res.push({room, dayId, rId, dayNr: day.DayNr, date: day.TransDate, k, roomsCapacity: hotel.roomsCapacity});
                        k += 1;
                    }
                }
            }
            let white = true;
            let lastDay;
            for (let row of res) {
                row.rooms = days[row.dayId];
                if (row.dayId != lastDay) {
                    white = !white;
                }
                row.rowWhite = white;
                lastDay = row.dayId;
            }
            return res;
        },
        getPaxByBase () {
            return this.recordStore.getPaxByBase(this.baseId);
        },
        checkPax () {
            if (this.ready) {
                return this.$refs.paxGruops.checkPax;
            } else {
                    return true;
            }
        },
        hasChild () {
            return this.recordStore.hasChild(this.baseId);
        },
        hasTL () {
            return this.recordStore.hasTL(this.baseId);
        }
    },
    methods: {
        async close () {
            if (!this.ready && !this.saved) {
                await this.remove();
            } else {
                await this.setPaxByGroups();
            }
            this.$emit('update:quoteGroups', false);
            this.eventUpdates();
            $('#booking-quote-groups').modal('hide');
        },
        eventUpdates () {
            EventBus.$emit('update-totals');
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-quotes-hotels');
        },
        async fixTable () {
            return;
            await this.$nextTick();
            setTimeout(() => {
                var $table = $('#table-groups'),
                    $bodyCells = $table.find('tbody tr:first').children(),
                    colWidth;

                // Get the tbody columns width array
                colWidth = $bodyCells.map(function() {
                    return $(this).width();
                }).get();

                // Set the width of thead columns
                $table.find('thead tr').each(function (i, v) {
                    $(this).children().each((i, v) => {
                        $(v).width(colWidth[i]);
                    });
                });

            }, 500)
        },
        date (date) {
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        showService (s) {
            if (s.Service && s.Service.NotPrice) return true;
            if (s.Quote.QuoteList && s.Quote.QuoteList[this.baseId]) {
                for (let p in s.Quote.QuoteList[this.baseId]) {
                    if (s.Quote.QuoteList[this.baseId][p] && s.Quote.QuoteList[this.baseId][p].Include) return true;
                }
            }
        },
        addGroup () {
            let groups = this.recordStore.addGroup(this.baseId);
            this.groups = Object.assign({}, groups);
        },
        removeRow (i) {
            delete this.groups[i];
            this.groups = Object.assign({}, this.groups);
        },
        cancel () {
            //this.groups = Object.assign({}, this.recordStore.QuoteGroups[this.baseId]);
            this.refresGroup();
            this.edit = false;
        },
        refresGroup () {
            this.refresh = !this.refresh;
            this.groups = this.recordStore.refresGroup(this.baseId);
        },
        async save () {
            this.recordStore.saveGroups(this.baseId, this.groups);
            this.updateTotals();
            this.ready = true;
            this.saved = true;
            this.edit = false;
            this.fixTable();
            this.eventUpdates();
        },
        showRoom (roomRow, dayNr) {
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && roomRow.QuoteList[this.baseId] && (roomRow.QuoteList[this.baseId].Include || this.edit)) return true;
            if (roomRow.Hotel && roomRow.Hotel.id  && roomRow.QuoteList && !roomRow.QuoteList[this.baseId]) return true;
        },
        async remove () {
            this.ready = false;
            this.recordStore.removeGroup(this.baseId)
        },
        async removeClose () {
            await this.remove();
            await this.$nextTick();
            await this.close();
        },
        async setPaxByGroups (dayId, type) {
            await this.recordStore.setPaxByGroups(this.baseId, dayId, type);
            this.eventUpdates();
        },
        async sumGroup (dayId, type) {
            await this.$nextTick();
            await this.setPaxByGroups(dayId, type)
            this.updateTotals();
        },
        updateTotals () {
            let v = this.recordStore.totalByPaxGroup[this.baseId];
            if (!v) this.recordStore.forceGetter('totalByPaxGroup');
            this.totalByPaxGroup = Object.assign({}, this.recordStore.totalByPaxGroup[this.baseId]);
            this.sumUpGroups = Object.assign({}, this.recordStore.sumUpGroups[this.baseId]);
        },
        isBoolean (groups) {
            for (let groupId in groups) {
                if (typeof groups[groupId] == 'object') return false;
            }
            return true;
        },
        changeView () {
            if (this.roomsView==3) {
                this.roomsView = 0;
            } else {
                this.roomsView += 1;
            }
        }
    },
}
</script>

<style scoped>

thead, tbody { display: block; }

tbody {
    height: 50vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.bg-white td {
  background-color: white;
}
.bg-white-blue {
  background-color: #f6f9ff !important;
}
.bg-white-blue td {
  background-color: #f6f9ff;
}

.group-day {
    width: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
}

.group-service {
    width: 600px !important;
    min-width: 600px !important;
    max-width: 600px !important;
}

.group-value {
    width: 90px !important;
    min-width: 90px !important;
    max-width: 90px !important;
}

.group-sm {
    width: 70px !important;
    min-width: 70px !important;
    max-width: 70px !important;
}

.modal-lg {
    min-width: 75vw !important;
}

.modal-auto {
    width: min-content;
    max-width: min-content;
}

.modal-header {
    min-width: 700px;
}
.rooms-input {
    max-width: 30px !important;
}
</style>

