<template>
  <div class="modal fade bd-example-modal-lg" id="select-hotel-modal" tabindex="-1" role="dialog"
      data-backdrop="static" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg modal-extra-large" role="document">
      <div class="modal-content">
        <div class="modal-header py-2 section-title">
            <label class="mt-1 mb-0 ml-4">
                {{tr('Edit Hotel')}}
            </label>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
              <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body body-hotel-selector">
          <transition name="fade" mode="out-in">
            <div v-if="showSelector">
              <div class="form-row">
                  <div class="form-group col-md-10">
                      <search-box v-model="searchBox"></search-box>
                  </div>
              </div>
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <button v-for="x in tags" type="button" class="btn btn-secondary btn-service-selected">{{x}}
                          <span aria-hidden="true" class="remove-item" @click="removeTag(x)">&times;</span>
                        </button>
                    </div>
                </div>

              <v-client-table :data="list" :columns="columnsNames" :options="options"
                v-if="list && !processing" @row-click="onRowClick">
              </v-client-table>
              <div v-if="processing">
                  <img class="loading-gif" src="@/img/loading.gif">
              </div>

            </div>
            <div v-else-if="recordStore.BookingDays && recordStore.BookingDays[dayId] && recordStore.BookingDays[dayId].BookingDayHotels[0]">
                <div class="form-row">
                    <div class="form-group col-md-12 row ml-3 form-material">
                        <div class="form-group form-default col-md-2">
                            <input v-model="recordStore.BookingDays[dayId].BookingDayHotels[0].Days"
                                class="form-control text-right" type="number" @change="setDays"
                                :class="{'fill': recordStore.BookingDays[dayId].BookingDayHotels[0].Days}"
                                :disabled="!canChangeHotel">
                                <label class="float-label ml-3">{{tr('Nights Quantity')}}</label>
                            </input>
                        </div>
                        <div class="col-md-10 text-right" v-if="recordStore.bookingConfirmed">
                            <label class="mr-4 text-danger font-weight-bold cursor-pointer" @click="setExchange"
                              v-if="canChangeHotel && !exchange">
                                <font-awesome-icon icon="lock-open" class="mr-2"/>
                                {{tr('Passenger prices unlocked')}}
                            </label>
                            <label class="mr-4 text-warning2 font-weight-bold cursor-pointer" @click="setExchange"
                              v-if="canChangeHotel && exchange">
                                <font-awesome-icon icon="lock"  class="mr-2"/>
                                {{tr('Passenger prices locked')}}
                            </label>
                            <i class="cursor-pointer mx-2" @click="setExchange"
                             	:class="{'row-primary-button': exchange}">
                                  <font-awesome-icon icon="exchange-alt"/>
                            </i>
                        </div>

                        <div class="form-group col-md-12 hotel-room-row">
                            <hotel-rooms
                                v-if="list"
                                :dayId="dayId"
                                :nights="recordStore.BookingDays[dayId].BookingDayHotels[0].Days"
                                :exchange="exchange"
                                :hotelsList="list"
                                :defaultHotel="selected"
                                :reportData="reportData"
                                @setHotel="updateHotelId"
                                @setRooms="setRooms"
                                @changeCity="changeCity"
                                @removeRow="$emit('removeRow')"
                            ></hotel-rooms>
                        </div>
                    </div>
                    <hotel-cost-list-report
                        class="select-hotel-cost"
                        v-if="this.reportParams.HotelId"
                        :options="reportParams"
                        :reportData.sync="reportData"
                    ></hotel-cost-list-report>
                </div>
            </div>
          </transition>
        </div>
        <div class="modal-footer footer--sticky">
          <button v-if="showSelector" type="button" class="btn btn-outline-secondary"
              :disabled="!selected" @click="select">{{tr('Select')}}</button>
          <button v-if="!showSelector && canChangeHotel" type="button" class="btn btn-warning"
               @click="change">{{tr('Change Hotel')}}</button>
          <button v-if="completed && !showSelector" type="button" class="btn btn-primary"
            @click="close">{{tr('Done')}}</button>
          <button type="button" class="btn btn-danger" @click="deleteHotel" v-if="canDeleteHotel">
            {{tr('Delete')}}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const hotelRooms = importVueComp('components/hotel', 'HotelRooms');
const hotelCostListReport = importVueComp('components/reports', 'HotelCostListReport');
const escape = importVueComp('components/tools', 'Escape');
var EventBus = require('@/tools/event-bus').default;

import { mapState } from 'vuex';
import Vue from 'vue/dist/vue.esm'
import {ServerTable, ClientTable, Event} from 'vue-tables-2'
let theme;
Vue.use(ClientTable, [theme = 'bootstrap5']);
Vue.use(ServerTable, [theme = 'bootstrap5']);
export default {
    name: 'hotel-selector-modal',
    mixins: [escape],
    props: ['dayId'],
    components: {
      'hotel-rooms': hotelRooms,
      ClientTable,
      'hotel-cost-list-report': hotelCostListReport,
    },
    data () {
        let self = this;
        return {
            searchBox: '',
            exchange: false,
            processing: false,
            selected: null,
            hotelCost: null,
            fields: ['Name', 'City', 'Type', 'HotelCategory'],
            showSelector: false,
            modified: false,
            tags: [],
            city: null,
            cityId: null,
            nights: null,
            reportData: null,
            currentRow: 0,
            hotelsList: null,
            reportParams: {
                emb: true,
                hideFilters: true,
                runMounted: true, 
                notFilterable: true,
                reportViewClass: 'hotel-report-view mb-0',
                colsView: 'col-12',
                rowClick: this.reportRowClick
            },
            columnsNames: ['Name', 'Type', 'City', 'HotelCategory'],
            options: {
                filterable: false,
                perPage: 10,
                headings: {'Name': tr('Hotel'), 'Type': tr('Type'), 'City': tr('City'), 'HotelCategory': tr('Category')},
                destroyEventBus: false,
                destroyEventBus: false,
                texts: vueTools.vueTableText(),
                templates: {},
                skin: 'table table-striped table-sm table-bordered table-bordered-hover',
                rowClassCallback: (row) => {
                    if (self.selected && self.selected.id==row.id) return 'background-green';
                    return false;
                },
            }
        }
    },
    computed:{
        ...mapState({
            recordStore: state => state.main.record,
            salesActions: state => state.main.salesActions,
        }),
        defaultExchange () {
            return api.defaultExchange;
        },
        canChangeHotel () {
            return true;
        },
        canDeleteHotel () {
            if (this.recordStore.isTemplate) return true;
            return this.salesActions && !this.exchange;
        },
        search () {
            let res = "";
            if (this.searchBox) res = this.searchBox;
            for (let tag of this.tags){
                res += ' ' + tag
            }
            return res;
        },
        dataList () {
            return this.hotelsList;
        },
        abmFields () {
            return tools.getAbmFields(this.fields);
        },
        list () {
            return tools.abmList(this);
        },
        getTemplates () {
            let self = this;
            let res = {}
            res.Type = function(t, row) {
                return {
                    ALL_INCLUSIVE: 'All Inclusive',
                    NORMAL: 'Normal'
                }[row.Type];
            }
            return res;
        },
        completed () {
            return this.recordStore &&
                this.recordStore.BookingDays &&
                this.recordStore.BookingDays[this.dayId] &&
                this.recordStore.BookingDays[this.dayId].BookingDayHotels &&
                this.recordStore.BookingDays[this.dayId].BookingDayHotels[0] &&
                this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].Days &&
                this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms.length>0 &&
                this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[0].RoomTypeId &&
                this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[0].Rooms;
        },
    },
    async mounted () {
        $('#select-hotel-modal').modal({backdrop: 'static', keyboard: false}, 'show');
        if (this.recordStore.bookingConfirmed) this.exchange = this.defaultExchange;
        if (!this.salesActions && !this.recordStore.isTemplate) this.exchange = true;
        if (this.exchange) {
            this.recordStore.BookingDays[this.dayId].setExchange(this.exchange);
        }
        this.processing = true;
        let day = this.recordStore.BookingDays[this.dayId];
        if (!this.bookingCityName && day && day.BookingDayServices && day.BookingDayServices.length>0){
            this.cityId = day.BookingDayServices[day.BookingDayServices.length-1].Service.CityId;
            if (day.BookingDayServices[day.BookingDayServices.length-1].Service &&
                day.BookingDayServices[day.BookingDayServices.length-1].Service.City) {
                    this.city = day.BookingDayServices[day.BookingDayServices.length-1].Service.City.Name;
            }
        }
        if (day && day.BookingDayHotels[0] && day.BookingDayHotels[0].City && day.BookingDayHotels[0].City.Name) {
            this.city = day.BookingDayHotels[0].City.Name;
        }
        if (this.city) this.tags.push(this.city)
        let found = false;
        if (this.recordStore.BookingDays[this.dayId] && this.recordStore.BookingDays[this.dayId].BookingDayHotels[0]) {
            for (let room of this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms) {
                if (room.HotelId) {
                    this.setReportHotel(room.HotelId);
                    found = true;
                }
            }
        }
        if (!found) {
            this.showSelector = true;
        }
        if (!api.hotelsList || api.hotelsList.length==0) {
            await api.addHotelsList();
        }
        this.hotelsList = api.hotelsList;
        if (this.recordStore.BookingDays[this.dayId] &&
            this.recordStore.BookingDays[this.dayId].BookingDayHotels[0] &&
            !this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].Days) {
            await this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].setDays(1)
        };
        this.options.templates = this.getTemplates;
        this.processing = false;
    },
    watch: {
        list () {
            let self = this;
            this.$nextTick(async function () {
                if (self.list && self.list.length==1) {
                    let res = await api.get('/api/hotel/' + self.list[0].id);
                    if (res) this.selected = res;
                    if (self.list[0] && self.list[0].id) this.setReportHotel(self.list[0].id);
                }
            })
        },
        booking: {
            handler () {
                        this.modified = true;
                    },
                    deep: true,
        },
    },
    methods: {
        async setExchange () {
            if (!this.salesActions) return;
            this.exchange = !this.exchange;
            await this.$nextTick();
            this.recordStore.BookingDays[this.dayId].setExchange(this.exchange);
        },
        changeCity () {
            if (this.city && this.tags.length==0) {
                this.tags.push(this.city);
                return;
            }
            if (this.tags.length>0) {
                this.tags = [];
                return;
            }
        },
        close () {
            this.recordStore.setDaysByHotel();
            $('#select-hotel-modal').modal('hide');
            EventBus.$emit('update-quotes-hotels');
            EventBus.$emit('update-totals');
            this.$emit('close');
        },
        async setDays () {
            await this.$nextTick()
            this.recordStore.setDaysByHotel();
            EventBus.$emit('update-quotes-hotels');
            EventBus.$emit('update-totals');
            return;
        },
        deleteHotel () {
            this.$emit('delete');
            this.close();
        },
        async select () {
            await this.recordStore.BookingDays[this.dayId].setHotel(this.selected, this.exchange);
            this.showSelector = false;
            await this.$nextTick();
            if (this.selected) {
                this.searchBox = null;
                //this.tags = [this.record.BookingDays[this.dayId].BookingDayHotels[0].City.Name];
            } else {
                this.close();
            }
        },
        async change () {
            this.showSelector = true;
            if (!api.hotelsList || api.hotelsList.length==0) {
                this.processing = true;
                await api.addHotelsList();
                this.processing = false;
            }
        },
        removeTag (record){
            let i = this.tags.indexOf(record);
            if (i>-1) this.tags.splice(i, 1)
        },
        evenListener (e) {
            if (e.keyCode == 13) {
                let x = document.activeElement;
                if (x){
                    this.tags.push(x.value);
                    this.searchBox = '';
                }
            }
            if (e.keyCode == 27) {
                this.close();
            }
        },
        async onRowClick (data) {
            let res = await api.get('/api/hotel/' + data.row.id);
            if (res) this.selected = res;
            this.setReportHotel(data.row.id);
        },
        async setReportHotel (hotelId) {
            this.reportParams.HotelId = hotelId;
            if (this.recordStore.QuoteDate && this.recordStore.SplitQuoteDate) {
                this.reportParams.TransDate = this.recordStore.BookingDays[this.dayId].QuoteDate;
            } else {
                this.reportParams.TransDate = this.recordStore.BookingDays[this.dayId].TransDate;
            }
            this.reportParams = Object.assign({}, this.reportParams);
        },
        updateHotelId (hotelId, rowNr) {
            this.reportParams.HotelId = hotelId;
            this.reportParams = Object.assign({}, this.reportParams);
            this.currentRow = rowNr
            this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].setRoomCost();
            EventBus.$emit('update-quotes-hotels');
        },
        setRooms (hotelId, rowNr) {
            //this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].setRooms();
            EventBus.$emit('update-quotes-hotels');
        },
        async reportRowClick (row) {
            let room = this.recordStore.BookingDays[this.dayId].BookingDayHotels[0].BookingDayRooms[this.currentRow];
            if (!room) return;
            room.RoomTypeId = row.RoomTypeId;
            room.RoomCategory = row.RoomCategoryId;
        },
    },
    beforeDestroy () {
        document.removeEventListener('keydown',this.evenListener);
    },
    created () {
        setTimeout(()=> document.addEventListener("keydown", this.evenListener), 1000);
    }

}
</script>

