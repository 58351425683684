<template>
    <div class="modal fade bd-example-modal-lg" id="booking-quote-groups" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="booking-quote-groups" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-auto modal-large" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{tr('Passenger Rooming')}}:
                        {{getPaxByBase}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"  
                        @click="close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!--booking-quote-groups 
                    class="modal-header form-material"
                    :groups.sync="groups"
                    :edit="edit"
                    :ready="ready"
                    ref="paxGruops"
                    :baseId="baseId"
                    @setEdit="edit = true"
                    @removeClose="removeClose"
                    @save="save"
                    @cancel="cancel"
                ></booking-quote-groups-->
                <!--div class="modal-body form-material"  v-if="saved">
                    <div class="w-100">
                        <div class="form-row">
                            <div class="col-3">
                                {{ tr('Passengers') }}
                            </div>
                            <div class="col-6">
                                {{ tr('Room Type') }}
                            </div>
                            <div class="col-1 text-center">
                                {{ tr('# Pax') }}
                            </div>
                            <div class="col-1 text-center">
                                {{ tr('# Rooms') }}
                            </div>
                        </div>
                        <div v-for="(row, rowId) of roomsAssigment"  class="form-row">
                            <select-input
                                class="col-3 my-1"
                                :fieldOptions="groupOptions"
                                @change="change(row)"
                                :currentValue.sync="row.groupId">
                            </select-input>
                            <select-input
                                class="col-6 my-1"
                                :fieldOptions="roomsOptions"
                                @change="change(row)"
                                :currentValue.sync="row.rId">
                            </select-input>
                            <number-input
                                labelClass="ml-4 form-group form-default"
                                class="col-1 my-1"
                                :v.sync="row.pax"
                                @change="change(row)"
                            ></number-input>
                            <number-input
                                labelClass="ml-4 form-group form-default"
                                class="col-1 my-1"
                                :v.sync="row.rooms"
                                @change="change(row)"
                            ></number-input>
                            <i class="add-item cursor-pointer ml-2" @click="removeRow(rowId)" >
                                <font-awesome-icon icon="trash-alt"/>
                            </i>
                        </div>
                        <div class="d-flex align-items-center col-12 mt-2">
                            <i class="add-item cursor-pointer ml-3" @click="addRow">
                                <font-awesome-icon icon="plus-circle"/>
                            </i>
                        </div>
                        <div class="fom-row text-center font-weight-bold">
                            <div class="col-12 text-danger" v-for="message of messages">
                                {{ message }}
                            </div>
                        </div>

                    </div>
                </div-->
                <div class="modal-body form-material">
                    <table class="table table-striped  table-sm table-bordered w-100">
                        <thead>
                            <tr>
                                <th>
                                    Rooms
                                </th>
                                <th v-for="(group, groupId) of groups">
                                    {{ group.Name }}
                                    <font-awesome-icon icon="circle" :color="colors[groupId]"/>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(row, rowId) of roomsOptions">
                                <td>
                                    {{row.rooms}} {{ row.label }} <br>
                                    <span class="d-flex justify-content-center">
                                        <span v-for="i in row.rooms" :key="i" class="border mx-1">
                                            <span v-for="c in row.capacity" :key="c" class="d-inline-block">
                                                <bed class="bed" :color="getColor(i, c, row)"></bed>
                                            </span>
                                        </span>
                                    </span>
                                </td>
                                <td v-for="(group, groupId) of groups">
                                    <input v-model="row.roomGroups[groupId].selected" type="checkbox" 
                                        class="mr-3" @change="setPaxByGroups"/>
                                    <span v-if="row.roomGroups[groupId].selected">
                                        <number-input
                                            class="my-0 d-inline-block pax-input"
                                            :v.sync="row.roomGroups[groupId].pax"
                                            :d="0"
                                            addClass="text-center"
                                            :hideLabel="true"
                                            @change="setPaxByGroups"
                                        ></number-input>
                                        in
                                        <span class="d-inline-block">
                                            {{ row.roomType }}
                                        </span>
                                        </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
const bookingQuoteGroups = importVueComp('components/booking', 'BookingQuoteGroups');
const sglBed = importVueComp('components/svg', 'SglBed');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'booking-quote-rooming',
    mixins: [escape],
    props: ['showRooming', 'baseId'],
    components: {
        'booking-quote-groups': bookingQuoteGroups,
        'bed': sglBed,
    },
    data () {
        return {
            groups: {},
            colors: {
                0: '#A52A2A', 
                1: '#0000FF', 
                2: '#FFA500', 
                3: '#808000', 
                4: '#800080', 
                5: '#00FFFF', 
                6: '#FFFF00', 
                7: '#7FFFD4', 
                8: '#FFC0CB',
            },
            roomsOptions: {},
        }
    },
    async mounted () {
        $('#booking-quote-groups').modal({backdrop: 'static', keyboard: false}, 'show');
        this.setRoomOptions();
        if (this.recordStore.QuoteGroups[this.baseId]) {
            this.groups = Object.assign({}, this.recordStore.QuoteGroups[this.baseId]);
            //this.setAssigment()
            this.ready = true;
        }
        window._booking = this.recordStore;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        getPaxByBase () {
            return this.recordStore.getPaxByBase(this.baseId);
        },
        dayId () {
            return this.showRooming - 1;
        },
    },
    methods: {
        setRoomOptions () {
            let res = {};
            let day = this.recordStore.BookingDays[this.dayId];
            for (let hotel of day.BookingDayHotels) {
                for (let rId in hotel.BookingDayRooms) {
                    let room = hotel.BookingDayRooms[rId];
                    if (!room.RoomCategory) continue;
                    if (!room.RoomTypeId) continue;
                    if (!room.HotelId) continue;
                    if (!room.QuoteList) continue;
                    if (!room.QuoteList[this.baseId]) continue;
                    if (!room.QuoteList[this.baseId].Include) continue;
                    let rooms = room.QuoteList[this.baseId].Rooms;
                    res[rId] = {
                        rId,
                        value: rId,
                        roomType: room.Room.Name,
                        label: `${room.Room.Name} ${room.Category.Name} ${room.Hotel.Name}`,
                        rooms,
                        capacity: room.Room.Pax, 
                        roomGroups: room.QuoteList[this.baseId].Groups
                        //disabled: this.roomsDiabled[rId]
                    }
                }
            }
            this.roomsOptions = Object.assign({}, res);
        },
        async close () {
            await this.setPaxByGroups();
            this.$emit('update:showRooming', false);
            $('#booking-quote-groups').modal('hide');
        },
        eventUpdates () {
            let day = this.recordStore.BookingDays[this.dayId];
            for (let hotel of day.BookingDayHotels) {
                for (let rId in hotel.BookingDayRooms) {
                    let ref = `room-${this.baseId}-${this.dayId}-${rId}`;
                    if (this.$parent.$refs && this.$parent.$refs[ref] && this.$parent.$refs[ref][0]) {
                        this.$parent.$refs[ref][0].setCapacity();
                    }
                }

            }
            EventBus.$emit('update-totals');
            //EventBus.$emit('update-quotes');
            //EventBus.$emit('update-quotes-hotels');
        },
        async setPaxByGroups () {
            await this.recordStore.setPaxByGroups(this.baseId, this.dayId, 'hotels');
            this.eventUpdates();
        },
        getColor (id, cId, row) {
            let colors = {}
            let j = 0;
            for (let groupId in row.roomGroups) {
                let group = row.roomGroups[groupId];
                if (!group.selected) continue;
                for (let i=0; i<group.pax; i++) {
                    colors[j] = this.colors[groupId];
                    j += 1;
                }
            }
            let cnt = 0;
            for (let i=1; i<=row.rooms; i++) {
                for (let c=1; c<=row.capacity; c++) {
                    if (i == id && c == cId) {
                        return colors[cnt];
                    }
                    cnt += 1;
                }
            }
        }
    },
}
</script>

<style scoped>


.modal-auto {
    width: min-content;
    max-width: min-content;
}

.modal-header {
    min-width: 700px;
}
.pax-input {
    max-width: 25px !important;
}
.bed {
    max-width: 30px !important;
    max-height: 30px !important;
}
</style>

