<template>
    <div class="form-material">
        <div class="w-25 form-row" v-if="groups">
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-primary btn-sm mb-1" :disabled="!checkPax" v-if="!ready"
                    @click="save">{{tr('Done')}}</button>
                <button type="button" class="btn btn-primary btn-sm mb-1" :disabled="!checkPax" v-else-if="edit"
                    @click="save">{{tr('Done')}}</button>
                <button type="button" class="btn btn-primary btn-sm mb-1" v-else-if="ready && !edit"
                    @click="$emit('setEdit')">{{tr('Edit')}}</button>
                <button type="button" class="btn btn-secondary btn-sm mb-1 ml-2" v-if="edit"
                    @click="$emit('cancel')">{{tr('cancel')}}</button>
            </div>
            <div class="col-12 d-flex justify-content-center">
                <button type="button" class="btn btn-warning btn-sm mb-1" @click="removeGroups = true" v-if="!removeGroups"
                    >{{tr('Delete Gruoups')}}</button>
                <button type="button" class="btn btn-secondary btn-sm mb-1 mr-2" @click="removeGroups = false" v-if="removeGroups">
                    {{tr('Cancel')}}</button>
                <button type="button" class="btn btn-danger btn-sm mb-1" @click="$emit('removeClose')" v-if="removeGroups">
                    {{tr('Sure?')}}</button>
            </div>
            <div class="col-12 d-flex justify-content-center" v-if="removeGroups">
                <span class="text-center">
                    {{ tr('This will remove groups configuration for all services and hotels in this quotation') }}
                </span>
            </div>
        </div>
        <div class="form-row w-75">
            <div v-for="(group, i) of groups" class="form-group col-12 my-0 py-0">
                <div class="form-row d-flex align-items-center">
                    <label for="Name" class="mr-0 pr-0 col-1 mb-0 mt-1"># {{parseInt(i)+1}}:</label>
                    <input-field
                        class="col-3"
                        addClass="my-0"
                        :field="{name: 'Name', editor: 'text', label: 'Group Name', hideLabel: i > 0}"
                        :defValue.sync="group.Name"
                        :readonly="!edit && ready"
                    ></input-field>
                    <number-input
                        labelClass="ml-4"
                        class="col-1 my-0"
                        :label="i==0? 'ADT': null"
                        :v.sync="group.ADT"
                        :disabled="!edit && ready"
                        @change="setADT(group)"
                    ></number-input>
                    <number-input v-if="hasChild"
                        labelClass="ml-4"
                        class="col-1 my-0"
                        :label="i==0? 'CHD': null"
                        :v.sync="group.CHD"
                        :disabled="!edit && ready"
                    ></number-input>
                    <number-input v-if="hasTL"
                        labelClass="ml-4"
                        class="col-1 my-0"
                        :label="i==0? 'TL': null"
                        :v.sync="group.TL"
                        :disabled="!edit && ready"
                        @change="setADT(group)"
                    ></number-input>
                    <booking-quote-groups-rooms
                        v-if="group.Rooms"
                        :roomsQty.sync="group.Rooms"
                        :edit="edit || !ready"
                        :defRooming="group.Rooms"
                    ></booking-quote-groups-rooms>
                    <i class="add-item cursor-pointer ml-2" @click="removeRow(i)" v-if="(edit || !ready) && i>1">
                        <font-awesome-icon icon="trash-alt"/>
                    </i>
                </div>
            </div>
            <div class="d-flex align-items-center col-12 mt-2">
                <i class="add-item cursor-pointer ml-3" @click="addGroup" v-if="edit || !ready">
                    <font-awesome-icon icon="plus-circle"/>
                </i>
            </div>
        </div>
    </div>
</template>

<script>
const bookingQuoteGroupsRooms = importVueComp('components/booking', 'BookingQuoteGroupsRooms');
import { mapState } from 'vuex';
export default {
    name: 'booking-quote-groups',
    props: ['groups', 'edit', 'ready', 'baseId'],
    components: {
        'booking-quote-groups-rooms': bookingQuoteGroupsRooms
    },
    data () {
        return {
            removeGroups: false,
        }
    },
    mounted () {
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        checkPax () {
            let adt = 0;
            let chd = 0;
            let tl = 0;
            for (let i in this.groups) {
                //if (!this.groups[i].ADT && !this.groups[i].CHD) return false;
                if (this.groups[i].ADT) adt += this.groups[i].ADT;
                if (this.groups[i].CHD) chd += this.groups[i].CHD;
                if (this.groups[i].TL) tl += this.groups[i].TL;
            }
            if (this.recordStore.BaseQuotes[this.baseId].ADT != adt) return false;
            if (this.recordStore.BaseQuotes[this.baseId].CHD && this.recordStore.BaseQuotes[this.baseId].CHD != chd) return false;
            if (this.recordStore.BaseQuotes[this.baseId].TL && this.recordStore.BaseQuotes[this.baseId].TL != tl) return false;
            return true;
        },
        hasChild () {
            return this.recordStore.hasChild(this.baseId);
        },
        hasTL () {
            return this.recordStore.hasTL(this.baseId);
        },
        roomTypes () {
            return this.recordStore.roomTypesObjects;
        }

    },
    methods: {
        async save () {
            this.$emit('update:groups', Object.assign({}, this.groups));
            //this.$emit('save');
            this.$parent.save();
            this.recordStore.assignRoomTypes(this.baseId);
            await this.recordStore.setPaxByGroups(this.baseId, undefined, 'hotels');
            //this.$parent.refresGroup();
            this.$parent.eventUpdates();
            this.$parent.updateTotals();
            //this.assignRoomTypes();
        },
        date (date) {
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        addGroup () {
            let groups;
            if (Object.keys(this.groups).length>1) {
                let group = this.recordStore.newGroup();
                let k = this.recordStore.getNextGroupId(this.groups);
                groups = Object.assign({}, this.groups)
                groups[k] = Object.assign({}, group);
            } else {
                groups = this.recordStore.addGroup(this.baseId);
            }
            this.$emit('update:groups', Object.assign({}, groups));
        },
        removeRow (i) {
            delete this.groups[i];
            this.$emit('update:groups', Object.assign({}, this.groups));
            //this.groups = Object.assign({}, this.groups);
        },
        assignRoomTypes () {
            for (let day of this.recordStore.BookingDays) {
                for (let hotel of day.BookingDayHotels) {
                    let groupsQty = {}
                    for (let roomId in hotel.BookingDayRooms) {
                        let room = hotel.BookingDayRooms[roomId];
                        //let capacity = room.Room.Pax * room.Rooms;
                        let assigned = 0;
                        for (let groupId in this.groups) {
                            let group = this.recordStore.QuoteGroups[this.baseId][groupId];
                            let paxGroup = (group.ADT? group.ADT: 0) + (group.CHD? group.CHD: 0) + (group.TL? group.TL: 0)
                            let rId = room.RoomTypeId;
                            if (!groupsQty[[groupId, rId]]) groupsQty[[groupId, rId]] = 0;
                            let roomsQty = this.groups[groupId].Rooms[rId];
                            if (roomsQty && assigned < room.QuoteList[this.baseId].Rooms && groupsQty[[groupId, rId]] < roomsQty) {
                                if (room.QuoteList[this.baseId].Groups) {
                                    room.QuoteList[this.baseId].Groups[groupId].rooms = roomsQty;
                                    let toAssign = this.roomTypes[rId].Pax * roomsQty;
                                    if (toAssign > paxGroup) toAssign = paxGroup;
                                    room.QuoteList[this.baseId].Groups[groupId].pax = toAssign;
                                    assigned += roomsQty;
                                    groupsQty[[groupId, rId]] += roomsQty;
                                }
                            } else if (!roomsQty) {
                                //console.log(111)
                                room.QuoteList[this.baseId].Groups[groupId].selected = false;
                            }
                        }
                        //console.log(room.QuoteList[this.baseId].Groups)
                    }
                }

            }

        },
        setADT (group) {
            //group.ADT = group.$ADT + group.TL;
        }
    },
}
</script>


<style scoped>
button {
    min-width: 100px !important;
    max-width: 120px !important;
    max-height: 30px !important;
    font-size: small;
}
</style>