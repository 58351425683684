<template>
    <div class="modal fade bd-example-modal-lg" id="booking-quote-groups" tabindex="-1" role="dialog"
        data-backdrop="static" aria-labelledby="booking-quote-groups" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-auto modal-large" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{tr('Passenger Rooming')}}:
                        {{getPaxByBase}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"  
                        @click="close" >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <booking-quote-groups 
                    class="modal-header form-material"
                    :groups.sync="groups"
                    :edit="edit"
                    :ready="ready"
                    ref="paxGruops"
                    :baseId="baseId"
                    @setEdit="edit = true"
                    @removeClose="removeClose"
                    @save="save"
                    @cancel="cancel"
                ></booking-quote-groups>
                <div class="modal-body form-material"  v-if="saved">
                    <div class="w-100">
                        <div class="form-row">
                            <div class="col-3">
                                {{ tr('Passengers') }}
                            </div>
                            <div class="col-6">
                                {{ tr('Room Type') }}
                            </div>
                            <div class="col-1 text-center">
                                {{ tr('# Pax') }}
                            </div>
                            <div class="col-1 text-center">
                                {{ tr('# Rooms') }}
                            </div>
                        </div>
                        <div v-for="(row, rowId) of roomsAssigment"  class="form-row">
                            <select-input
                                class="col-3 my-1"
                                :fieldOptions="groupOptions"
                                @change="change(row)"
                                :currentValue.sync="row.groupId">
                            </select-input>
                            <select-input
                                class="col-6 my-1"
                                :fieldOptions="roomsOptions"
                                @change="change(row)"
                                :currentValue.sync="row.rId">
                            </select-input>
                            <number-input
                                labelClass="ml-4 form-group form-default"
                                class="col-1 my-1"
                                :v.sync="row.pax"
                                @change="change(row)"
                            ></number-input>
                            <number-input
                                labelClass="ml-4 form-group form-default"
                                class="col-1 my-1"
                                :v.sync="row.rooms"
                                @change="change(row)"
                            ></number-input>
                            <i class="add-item cursor-pointer ml-2" @click="removeRow(rowId)" >
                                <font-awesome-icon icon="trash-alt"/>
                            </i>
                        </div>
                        <div class="d-flex align-items-center col-12 mt-2">
                            <i class="add-item cursor-pointer ml-3" @click="addRow">
                                <font-awesome-icon icon="plus-circle"/>
                            </i>
                        </div>
                        <div class="fom-row text-center font-weight-bold">
                            <div class="col-12 text-danger" v-for="message of messages">
                                {{ message }}
                            </div>
                        </div>

                    </div>
                </div>
                {{ checkRooms }}
                {{ checkPaxRooms }}
                {{ checkPaxQuantity }}
            </div>
        </div>
    </div>
</template>

<script>
const escape = importVueComp('components/tools', 'Escape');
const bookingQuoteGroups = importVueComp('components/booking', 'BookingQuoteGroups');
var EventBus = require('@/tools/event-bus').default;
import { mapState } from 'vuex';
export default {
    name: 'booking-quote-rooming',
    mixins: [escape],
    props: ['showRooming', 'baseId'],
    components: {
        'booking-quote-groups': bookingQuoteGroups,
    },
    data () {
        return {
            groups: {},
            ready: false,
            edit: false,
            saved: false,
            roomsOptions: [],
            roomsAssigment: [
                {groupId: null, rId: null, rooms: null, pax: null},
                {groupId: null, rId: null, rooms: null, pax: null},
            ]
        }
    },
    async mounted () {
        $('#booking-quote-groups').modal({backdrop: 'static', keyboard: false}, 'show');
        this.setRoomOptions();
        if (this.recordStore.QuoteGroups[this.baseId]) {
            this.groups = Object.assign({}, this.recordStore.QuoteGroups[this.baseId]);
            this.setAssigment()
            this.saved = true;
            this.ready = true;
        }
        /*if (!this.groups || Object.keys(this.groups).length == 0) {
            this.addGroup();
            this.ready = true;
        }*/
        this.setRoomOptions();
        window._booking = this.recordStore;
    },
    computed: {
        ...mapState({
            recordStore: state => state.main.record,
        }),
        storeGroups () {
            return this.recordStore.QuoteGroups[this.baseId];
        },
        groupOptions () {
            let res = [];
            for (let groupId in this.groups) {
                let group = this.groups[groupId];
                res.push({
                    value: groupId, 
                    label: `${group.Name}: ${this.paxPerGroup[groupId]} Pax`,
                })
            }
            return res;
        },
        getPaxByBase () {
            return this.recordStore.getPaxByBase(this.baseId);
        },
        checkPax () {
            if (this.ready) {
                return this.$refs.paxGruops.checkPax;
            } else {
                return true;
            }
        },
        roomsDiabled () {
            let disabled = {};
            for (let id in this.roomsCalculation){
                let row = this.roomsCalculation[id];
                if (row.total <= row.assignned) {
                    disabled[id] = true
                } else {
                    disabled[id] = false;
                };
            }
            return disabled;
        },
        roomsCalculation () {
            let res = {}
            for (let row of this.roomsOptions) {
                res[row.rId] = {total: row.rooms, assignned: 0, label: row.label}
            }
            for (let row of this.roomsAssigment) {
                if (!res[row.rId]) continue;
                if (!row.rooms) continue;
                res[row.rId].assignned += row.rooms;
            }
            return res;
        },
        checkRooms () {
            for (let id in this.roomsCalculation){
                let row = this.roomsCalculation[id];
                if (row.total != row.assignned) return row.label;
            }
            return false;
        },
        messages () {
            let res = [];
            if (this.checkRooms) res.push(tr(`Rooms quantity doest not mach with rooms assigned: ${this.checkRooms}`));
            let error1 = this.checkPaxRooms;
            let error2 = this.checkPaxQuantity;
            if (error1 != false) res.push(`${tr('Pax quantity for')} ${this.groups[error1].Name} ${tr('greater thatn rooms capacity')}`);
            if (error2 != false) res.push(`${tr('Pax assigned does not match with quantity of pax in group')} ${this.groups[error2].Name}`);
            //if (this.checkRooms) res.push(tr('Rooms quantity doest not mach with rooms assigned'));
            return res;
        },
        checkPaxRooms () {
            let res = {}
            for (let groupId in this.paxPerGroup) {
                res[groupId] = {pax: this.paxPerGroup[groupId], roomsCapacity: 0}
            }
            for (let row of this.roomsAssigment) {
                if (!res[row.groupId]) continue;
                if (!row.rooms) continue;
                let room = _.find(this.roomsOptions, (r)=> r.rId == row.rId);
                if (room) {
                    res[row.groupId].roomsCapacity += row.rooms * room.capacity;
                }
            }
            for (let id in res){
                let row = res[id];
                if (!row.roomsCapacity) continue;
                //if (!row.roomsCapacity || row.pax > row.roomsCapacity) return `Error in ${this.groups[id].Name}`;
                if (!row.roomsCapacity || row.pax > row.roomsCapacity) return id;
            }
            return false;
        },
        checkPaxQuantity () {
            let res = {}
            for (let groupId in this.paxPerGroup) {
                res[groupId] = {pax: this.paxPerGroup[groupId], assignned: 0}
            }
            for (let row of this.roomsAssigment) {
                if (!res[row.groupId]) continue;
                if (!row.pax) continue;
                res[row.groupId].assignned += row.pax;
            }
            for (let id in res){
                let row = res[id];
                //if (row.assignned && row.pax && row.pax != row.assignned) return `Error in ${this.groups[id].Name}`;
                if (row.assignned && row.pax && row.pax != row.assignned) return id;
            }
            return false;
        },

        dayId () {
            return this.showRooming - 1;
        },
        paxPerGroup () {
            let res = {};
            for (let groupId in this.groups) {
                let group = this.groups[groupId];
                res[groupId] = (group.ADT? group.ADT: 0) + (group.CHD? group.CHD: 0) + (group.TL? group.TL: 0)
            }
            return res;
        }
    },
    methods: {
        setRoomOptions () {
            let res = [];
            let day = this.recordStore.BookingDays[this.dayId];
            for (let hotel of day.BookingDayHotels) {
                for (let rId in hotel.BookingDayRooms) {
                    let room = hotel.BookingDayRooms[rId];
                    if (!room.RoomCategory) continue;
                    if (!room.RoomTypeId) continue;
                    if (!room.HotelId) continue;
                    if (!room.QuoteList) continue;
                    if (!room.QuoteList[this.baseId]) continue;
                    if (!room.QuoteList[this.baseId].Include) continue;
                    let rooms = room.QuoteList[this.baseId].Rooms;
                    res.push({
                        rId,
                        value: rId,
                        label: `${room.Rooms} ${room.Room.Name} ${room.Category.Name} ${room.Hotel.Name}`,
                        rooms,
                        capacity: room.Room.Pax, 
                        disabled: this.roomsDiabled[rId]
                    })
                }
            }
            this.roomsOptions = Object.assign([], res);
        },
        addRow () {
            this.roomsAssigment.push({groupId: null, rId: null, rooms: null})
        },
        removeRow (i) {
            this.roomsAssigment.splice(i, 1)
            this.change()
        },
        async close () {
            if (!this.ready && !this.saved) {
                await this.remove();
            } else {
                await this.setPaxByGroups();
            }
            this.$emit('update:showRooming', false);
            this.eventUpdates();
            $('#booking-quote-groups').modal('hide');
        },
        eventUpdates () {
            let day = this.recordStore.BookingDays[this.dayId];
            for (let hotel of day.BookingDayHotels) {
                for (let rId in hotel.BookingDayRooms) {
                    let ref = `room-${this.baseId}-${this.dayId}-${rId}`;
                    if (this.$parent.$refs && this.$parent.$refs[ref] && this.$parent.$refs[ref][0]) {
                        this.$parent.$refs[ref][0].setCapacity();
                    }
                }

            }
            EventBus.$emit('update-totals');
            //EventBus.$emit('update-quotes');
            //EventBus.$emit('update-quotes-hotels');
        },
        date (date) {
            if (!date) return "";
            return moment(date).locale(api.language).format('DD MMM')
        },
        addGroup () {
            let groups = this.recordStore.addGroup(this.baseId);
            this.groups = Object.assign({}, groups);
        },
        cancel () {
            this.groups = Object.assign({}, this.recordStore.QuoteGroups[this.baseId]);
            this.edit = false;
        },
        async save () {
            this.recordStore.saveGroups(this.baseId, this.groups);
            this.ready = true;
            this.saved = true;
            this.edit = false;
            this.setAssigment();
            this.eventUpdates();
        },
        async remove () {
            this.ready = false;
            this.recordStore.removeGroup(this.baseId)
        },
        async removeClose () {
            await this.remove();
            await this.$nextTick();
            await this.close();
        },
        async setPaxByGroups () {
            await this.recordStore.setPaxByGroups(this.baseId, this.dayId, 'hotels');
            this.eventUpdates();
        },
        async change () {
            await this.$nextTick();
            this.setRoomOptions();
            this.roomsAssigment = Object.assign([], this.roomsAssigment);
            let day = this.recordStore.BookingDays[this.dayId];
            for (let hotel of day.BookingDayHotels) {
                for (let rId in hotel.BookingDayRooms) {
                    let room = hotel.BookingDayRooms[rId];
                    if (!room.QuoteList) continue;
                    if (!room.QuoteList[this.baseId]) continue;
                    if (!room.QuoteList[this.baseId].Include) continue;

                    for (let groupId in this.groups) {
                        let row = _.find(this.roomsAssigment, (r) => {
                            return r.rId == rId && r.groupId == groupId;
                        });
                        if (row) {
                            room.QuoteList[this.baseId].Groups[groupId] = {
                                rooms: row.rooms,
                                pax: row.pax,
                            };
                        } else {
                            room.QuoteList[this.baseId].Groups[groupId] = false;
                        }
                    }
                }
            }
            this.setPaxByGroups();
        },
        setAssigment () {
            let res = [];
            let day = this.recordStore.BookingDays[this.dayId];
            for (let hotel of day.BookingDayHotels) {
                for (let rId in hotel.BookingDayRooms) {
                    let room = hotel.BookingDayRooms[rId];
                    if (!room.QuoteList) continue;
                    if (!room.QuoteList[this.baseId]) continue;
                    if (!room.QuoteList[this.baseId].Include) continue;
                    if (!room.QuoteList[this.baseId].Groups) continue;
                    for (let groupId in this.groups) {
                        let value = room.QuoteList[this.baseId].Groups[groupId] 
                        if (!value) continue;
                        if (typeof value == 'object') {
                            res.push({
                                groupId,
                                rId,
                                rooms: value.rooms,
                                pax: value.pax
                            })
                        }
                    }
                }
            }
            if (res.length > 0) {
                this.roomsAssigment = Object.assign([], res);
            }
        }
    },
}
</script>

<style scoped>


.modal-auto {
    width: min-content;
    max-width: min-content;
}

.modal-header {
    min-width: 700px;
}
.rooms-input {
    max-width: 30px !important;
}
</style>

