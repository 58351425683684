<template>
    <div class="col-md-12">
        <div v-for="pType of getPaxTypesByBase" >
            <div class="form-row" v-if="totals && totals[baseId] && totals[baseId][pType] && recordStore.Totals[baseId]">
                <div class="col-md-1">
                </div>
                <div class="col form-group ">
                    <input v-model="recordStore.Totals[baseId][pType].Selected" type="checkbox"
                        class="form-check-input" :disabled="!canEdit" @change="setSelected"/>
                    <label v-if="totals[baseId][pType].Rooms">{{pType}} {{tr('Room')}} {{getRoomsNames(totals[baseId][pType].Rooms)}} </label>
                    <label v-else>{{pType}} </label>
                </div>
                <div class="col">
                    <number-input
                        v-if="recordStore.totals[baseId][pType]"
                        :addClass="recordStore.Totals[baseId][pType].OverPersonPrice? 'border-warning': ''"
                        :currency="recordStore.Currency"
                        :label="recordStore.CurrencyId"
                        :disabled="!canEdit"
                        @change="change($event, baseId, pType, 'PersonPrice')"
                        :v.sync="recordStore.totals[baseId][pType].PersonPrice"></number-input>
                </div>
                <div class="col">
                    <number-input
                        v-if="recordStore.totals[baseId][pType]"
                        :addClass="recordStore.Totals[baseId][pType].OverPrice? 'border-warning': ''"
                        :currency="recordStore.Currency"
                        :label="recordStore.CurrencyId"
                        :disabled="!canEdit"
                        @change="change($event, baseId, pType, 'Price')"
                        :v.sync="recordStore.totals[baseId][pType].Price"></number-input>
                </div>
                <div class="col" v-if="recordStore.CurrencyId!=recordStore.PriceCurrencyId">
                    <number-input
                        :label="recordStore.PriceCurrencyId"
                        :v="getTotalInCurrency(totals[baseId][pType].PersonPrice)"
                        :currency="recordStore.PriceCurrency"
                        disabled="true"
                    ></number-input>
                </div>
                <div class="col" v-if="recordStore.CurrencyId!=recordStore.PriceCurrencyId">
                    <number-input
                        :label="recordStore.PriceCurrencyId"
                        :v="getTotalInCurrency(totals[baseId][pType].Price)"
                        :currency="recordStore.PriceCurrency"
                        disabled="true"
                    ></number-input>
                </div>
                <div class="col-md-5 form-group form-default">
                    <input v-model="recordStore.Totals[baseId][pType].Comment" class="form-control"
                        :class="{'fill': recordStore.Totals[baseId][pType].Comment}"
                        type="text" :disabled="!canEdit">
                        <span class="form-bar"></span>
                        <label class="float-label">
                                {{tr('Comment')}}
                        </label>
                    </input>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;
export default {
    name: 'booking-quote-total-all',
    props: ['quote', 'baseId'],
    components: {
    },
    data () {
        return {
            totals: {},
        }
    },
    mounted () {
        this.setTotals();
        EventBus.$on('update-totals', this.setTotals);
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            recordStore: state => state.main.record,
        }),
        canEdit () {
            return this.salesActions;
        },
        getPaxTypesByBase () {
            return this.recordStore.getPaxTypesByBase(this.baseId);
        },
    },
    methods: {
        setTotals () {
            this.totals = Object.assign({}, this.recordStore.totals);
        },
        getTotalInCurrency (price) {
            return this.recordStore.getTotalInCurrency(price);
        },
        setSelected () {
            this.recordStore.forceGroupGetters = !this.recordStore.forceGroupGetters;
            //this.recordStore.setValue({fieldName: 'forceGroupGetters', value: !this.recordStore.forceGroupGetters});
            EventBus.$emit('update-totals');
        },
        getRoomsNames (rooms) {
            let r = [];
            for (let roomId in rooms) {
                if (rooms[roomId].Checkin) continue;
                if (r.indexOf(rooms[roomId].Name)==-1) {
                    r.push(rooms[roomId].Name);
                }
            }
            return r.join(' / ');
        },
        async change (value, baseId, pType, fieldName) {
            await this.recordStore.Totals[baseId][pType].setValue({fieldName, value});
            EventBus.$emit('update-totals');
            EventBus.$emit('update-quotes');
        },
    },
    beforeDestroy() {
        EventBus.$off('update-totals', this.setTotals);
    },
}
</script>

