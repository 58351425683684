<template>
    <div class="w-100" v-if="ready">
        <div class="mb-3 section-title">
            <label>{{tr('Quotations')}}</label>
        </div>
        <div class="my-2 mr-5" v-for="(quote, baseId) in bases">
            <a data-toggle="collapse" :data-target="'#base-' + baseId" aria-expanded="false" :aria-controls="'#base-' + baseId" @click="toggle(baseId)">
                <div class="my-3">
                    <div class="row bg-silver">
                        <div class="col-md-3" :id="'quote-label-' + baseId">
                            <label class="float-left mb-0"> {{getBasePaxTypes(quote, baseId)}}</label>
                        </div>
                    </div>
                </div>
            </a>
            <div class="collapse" :id="'base-' + baseId">
                <!--div v-if="loading === baseId">
                    <i>{{tr('loading')}}...</i>
                </div-->
                <booking-quote
                    v-if="recordStore.QuoteBy != 'SERVICE'"
                    :quote.sync="quote"
                    :baseId="baseId"
                    @remove="removeBase"
                    @copy="copyBase"
                ></booking-quote>
                <booking-quote-table
                    v-else
                    :quote.sync="quote"
                    :baseId="baseId"
                    @remove="removeBase"
                    @copy="copyBase"
                ></booking-quote-table>
            </div>
        </div>
        <div v-if="canAddQuote" class="mt-3">
            <i class="add-item cursor-pointer" @click="showInput">
               <font-awesome-icon icon="plus-circle"/>
            </i>
        </div>
        <input-quote v-if="showInputQuote" @save="addBase" @close="showInputQuote = false"></input-quote>
        <booking-quote-groups-table
            :quoteGroups.sync="quoteGroups"
            v-if="quoteGroups"
        ></booking-quote-groups-table>
    </div>
</template>

<script>
const inputQuote = importVueComp('components/booking', 'InputQuote');
const hotelSelectorModal = importVueComp('components/hotel', 'HotelSelectorModal');
const bookingQuoteHotelRoom = importVueComp('components/booking', 'BookingQuoteHotelRoom');
const bookingQuoteService = importVueComp('components/booking', 'BookingQuoteService');
const bookingQuoteFlights = importVueComp('components/booking', 'BookingQuoteFlights');
const bookingQuote = importVueComp('components/booking', 'BookingQuote');
const bookingQuoteTable = importVueComp('components/booking', 'BookingQuoteTable');
const bookingQuoteGroupsTable = importVueComp('components/booking', 'BookingQuoteGroupsTable');
//const bookingQuoteModal = importVueComp('components/booking', 'BookingQuoteModal');
import { mapState } from 'vuex';
var EventBus = require('@/tools/event-bus').default;

export default {
    name: 'booking-quote-list',
    components: {
        'input-quote': inputQuote,
        'hotel-selector-modal': hotelSelectorModal,
        'booking-quote-hotel-room': bookingQuoteHotelRoom,
        'booking-quote-service': bookingQuoteService,
        'booking-quote-flights': bookingQuoteFlights,
        'booking-quote': bookingQuote,
        'booking-quote-table': bookingQuoteTable,
        'booking-quote-groups-table': bookingQuoteGroupsTable,
        //'booking-quote-modal': bookingQuoteModal,
    },
    data () {
        return {
            showInputQuote: false,
            edit: null,
            updating: null,
            hotelSelector: false,
            currentDay: null,
            bookingCityId: null,
            hotelInfo: {},
            bookingCityName: null,
            currentBaseId: null,
            bases: {},
            quoteBaseId: null,
            currentQuote: null,
            ready: false,
            quoteGroups: false,
            showQuotes: {},
            loading: null,
        }
    },
    mounted () {
        EventBus.$on('update-bases', this.setBases);
        this.setBases();
        for (let baseId in this.bases) {
            this.showQuotes[baseId] = false;
        }
        this.ready = true;
    },
    computed: {
        ...mapState({
            user: state => state.main.user,
            salesActions: state => state.main.salesActions,
            managerActions: state => state.main.managerActions,
            recordStore: state => state.main.record,
        }),
        canAddQuote () {
            return this.salesActions;
        }
    },
    methods: {
        async toggle (baseId) {
            /*await this.$nextTick();
            //let t = 1;
            this.loading = baseId;
            //if (this.showQuotes[baseId]) {
                //t = 2000;
            //}
            //setTimeout(() => {
                this.showQuotes[baseId] = !this.showQuotes[baseId];
                this.showQuotes = Object.assign({}, this.showQuotes);
                this.loading = null;
            //}, t);*/
        },
        async addBase (groups) {
            this.showInputQuote = false;
            await this.recordStore.addBaseGroups(groups);
            this.setBases();
            EventBus.$emit('update-totals');
            api.setProcessing(false);
        },
        async copyBase (baseId) {
            let newBaseId = this.recordStore.getNextBaseId(this.recordStore.bases);
            await this.recordStore.copyBase(baseId);
            await this.$nextTick();
            this.setBases();
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
        },
        async removeBase (baseId) {
            this.ready = false;
            delete this.bases[baseId];
            this.bases = Object.assign({}, this.bases);
            await this.recordStore.removeBase(baseId)
            await this.$nextTick();
            this.setBases();
            EventBus.$emit('update-quotes');
            EventBus.$emit('update-totals');
            this.ready = true;
        },
        showInput () {
          if (!this.recordStore.CurrencyId) {
              this.recordStore.invalid.CurrencyId = true;
              this.recordStore.invalid = Object.assign({}, this.recordStore.invalid);
              alert(tr('Currencies required'))
              return;
          }
          if (!this.recordStore.PriceCurrencyId) {
              this.recordStore.invalid.PriceCurrencyId = true;
              this.recordStore.invalid = Object.assign({}, this.recordStore.invalid);
              alert(tr('Currencies required'))
              return;
          }
          if (this.recordStore.QuoteBy=='SERVICE' && !this.recordStore.QuoteViewType) {
              this.recordStore.invalid.QuoteViewType = true;
              this.recordStore.invalid = Object.assign({}, this.recordStore.invalid);
              alert(tr('Fields required'))
              return;
          }
          this.showInputQuote = true;
        },
        getBasePaxTypes (quote, baseId) {
            return this.recordStore.getBasePaxTypes(quote, baseId);
        },
        setBases () {
            this.bases = Object.assign({}, this.recordStore.bases);
            this.$parent.setHasVariants();
        },
        closeQuote () {
            this.quoteBaseId = null;
            this.currentQuote = null;
        },
        showQuote (quote, baseId) {
            this.quoteBaseId = baseId;
            this.currentQuote = quote;
        },
        getBGClass (idx) {
            if (idx % 2 == 0) return 'bg-silver';
            return '';
        },
        setGroups () {
            this.quoteGroups = true;
        }
    },
    beforeDestroy() {
        //EventBus.$off('update-quotes');
        EventBus.$off('update-bases', this.setBases);
    },
}
</script>

